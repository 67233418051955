<template>
  <div class="home-container">
    <!-- Header personalizado -->
    <header class="home-header">
      <img src="@/assets/logo_gob.png" alt="Logo" class="logo">
      <b-nav-item-dropdown v-if="$store.getters.tienePermiso('administracion')" text="Administración">
        <b-dropdown-item to="/estatusaudiencia">Estatus de audiencias</b-dropdown-item>
        <b-dropdown-item to="/tipotarjeta">Tipos de tarjetas informativas</b-dropdown-item>
        <b-dropdown-item to="/tipoevento">Tipos de evento</b-dropdown-item>
        <b-dropdown-item to="/tipoactor">Tipos de actores</b-dropdown-item>
        <b-dropdown-item to="/partidopolitico">Partidos políticos</b-dropdown-item>
        <b-dropdown-item to="/tipoconflicto">Tipos de conflicto</b-dropdown-item>
        <b-dropdown-item to="/tipoinformacion">Tipos de información</b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item to="/roles">Roles</b-dropdown-item>
        <b-dropdown-item to="/funciones">Funciones</b-dropdown-item>
        <b-dropdown-item to="/usuarios">Usuarios</b-dropdown-item>
        <b-dropdown-item to="/bitacora">Bitacora</b-dropdown-item>
        <b-dropdown-item to="/errores">Errores</b-dropdown-item>
      </b-nav-item-dropdown>
      <div class="profile-container">
        <div class="profile">
          <router-link to="/perfil">
          <img src="@/assets/perfil.jpg" alt="Foto de Perfil" class="profile-image">
        </router-link>
        </div>
        <button @click="cerrarSesion" class="logout-button">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAa9JREFUSEvtlm1uwjAMhm1BK3GLcpKNm8BJtp0EdhLgJPQWlRqQkZP0gzZ20oLEfoyfUcJjv68/ivCmH76JC7PAZMwnAHz4oM+YZaepCTwDPnrY5h+cIvvflpqqqoDlcgsAJ/bTF9eDx80dzLLvl2QchLiqfgQ3Z0QlIP7GAlClHkA5EVvB0WAcfKdVuwiWoEwPgf05y/xlpY7ANTBLyZK2mTbeSeBAUFwTm5DnQfDgj3+GfmlgC6/rPSByMY6CboIIg/sPr9c1rlZlP+oo2HXBxb8ZBc7nOpjogHm+G0oVA3vJG6uCckvgCyAWIIBT+rSVm6jEPF8P30gekybTJDDLmmUjjgRWZUoEq6pJYNePgkxJYGOcaoJd8XYi2mGeH1JgbZ/X9RYQ9x4cfB8GczssFkdfYCXcbpthS2mBUJdtsLDEdhpNoAnVTcaIE68frL4k+oMkMnttbxPxxCo0b9XJ1XrV7eH+4C/tAgA423sOxB9+zVznU3FGJ4HbAIzpto5qbtouVj0ejcku+8fsnAVWgdjyT/ZYTa6qiimVnjQyp/Ts3LuzvjLnwl4i9bPwOzJlLC6ASrD1AAAAAElFTkSuQmCC" alt="Cerrar Sesión" class="logout-icon">
          Cerrar Sesión
        </button>
      </div>
    <!-- Ejemplo dinámico en Vue.js para cargar la imagen de perfil desde la API 
   <div class="profile">
   <img :src="userProfileImage" alt="Foto de Perfil">
   </div>-->
    </header>
    <div class="tlahuicole">
      <img src="@/assets/logo_tlah.png">
    </div>
    <div class="floating-button-container">
      <router-link to="/panel-control" class="floating-button">
      PANEL DE CONTROL
      </router-link>
      <router-link to="/informes" class="floating-button2">
        INFORMES
      </router-link>
    </div>
    <!-- Secciones de los Ejes -->
    <main class="home-content">
      <div class="ejes">
        <router-link to="/base-datos" class="eje">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAAzRJREFUaEPtmoFR6zAMhu1JgEmgkwCTAJNQJqFMQpnE9A8WqI6TSLaj5Epyx5W+Z8f+9NuyJOPdP3v8P+N1G/ClK74pvCl8YRaYfUmHEK6jze7iJ33H1yOz59F7f5jbvs2BI+CDc+7WOUeQGg4YAT8fp/6H1kZoAswgnzRkwraAh/JvLeCrgEMIUPDVOceXqZCjqBngH2vAi4CjolATS3eJZ++ce/Hecx8gmocaOKr6Lnr7XyPal/j8Yn2v2O9YJZo9X6S2ClgBiz2ndjqJR4fTm1pBamgt8OfIfsXgcCzPSvUHm0cDQPX7EfVxnN1IxxQDhxDgnHIWByj2E/bVbE8IAWPDb+QcJMYXGVoDnFMX5+RuNsrkxSPOUjwPDXBIwfzJrFawNE6EhvH5I17W4gmHEHrAiIg0+6fWOBEWW6vnzaXGrwUGQ3NnlTPM1AlhCUzza+68WMgKLz0azS0BzMHpHFZnQKXJx5LA6Yqk8I+nfmm0BfXop2irrwm4CEDbyQoYEU7Wa2onLGzfhZKnXLsXy5sA0yAsIMBx0TpV7E6B03v3lB3ljkhTYK5O4nRK9iWvePxCJmMUB0FV57DEqtEApHr6Sed459ikif2qFBbuxapmG/DP6hCtVlEjyDEQS4vTsipJWeehENMK2CSOZpkSct5sZdQKeLY4OvHKk9VRa2AOjjOzuoCurXUvBXyWlMcCOv4NBT06X+FgumOIFe3wlYKWohuLNQC38lOi91gBIwPS1JJFk59ohNXRC19NgDGI4S0EjItrluMqAg9WQ5YU0KVKd4WEtAS7CuBMAkGOCAYYiqXP7oejc0O/US+/OmCpfKXtNmCrWFrqGUuVHOpnpTDKKukRtJZC/CxXLbjMQv0qfUwSiIlCPCojqHVNPpr0EJ4WKg/VrJYqxKtWmRiYxb7pRVZOcctC/E5aGsJEVcAMekzpnAG6s5X9R4tCvPr2vwiYQdMF9eS+maGB2EmlY6sVzkRU5oV4zRJuCkwv0ybrSsV7hXhl/7PmVQrnBm5ciK+unMyi8JjFM39ciua5PzBVX62WKN1c4ZJJWPbZgC2tvcRYm8JLWN1yzE1hS2svMdY3/vOyTJKAAfQAAAAASUVORK5CYII=" class="icono"/>
          <p>BASE DE DATOS</p>
        </router-link>
        <router-link to="/sistema-reportes" class="eje">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAAAXNSR0IArs4c6QAABLlJREFUeF7tm2tS3DAMgB0nnKNwEuAkhZNQTtLlJHCTLucgsVvt2DNmG+tlhULqzOyv9fOzJMuyPLj+sQkM7JK9oOuwBELQYXVYAgKCol2yOiwBAUHRLlkdloCAoOgmkvX29nYzDMPlMAzXaSyvIYSXaZqOwzAcBeMji87zfAd9Oee+OedeY4xH+G3RlymsNPAH5xwMvvYdvPePrdCWZfnpnLtD+gFoj+M4vrT2lfswgQWS5L2HwWOQ3s0rxng/TdOBFJ2zAqmvZ269GOPLOI73FsCaYcUYL0MIv7iDPysHK/+DWzdJLiyK+PPeX7UCa4LVCOo04RDC7cXFxQs1e6lErbR39N7ftgBrgsWwGxQD+J81iWVZIqcxrAyo5DRNt9p21LAspKoYNKqOy7KAqsLG0fy1qKMalpFUnSZPrfiyLGAT2ZsHQfQABl9DXQXLWKpO466t+Ef2RQFUwTIwtn+NC1wJcCbP//De31ipYG5b67aoYFnaEGo1N/pfpYpaWM0700YQ2M2O4yieu7jCFjaEPUPDgppdUQxrByqYkYtOD1BJA8tyGzeUFVlTlLuy1poI1l5UMIOQqqII1o5UMDvDosiHCNY8z8/DMIDfs4tPqopsWHtTQY0qsmFt4bV/BvGUePNsWHuzV8VCsb15Cawv77XXJJnrzbNg7dVeSe0WC9aOVVDkzXNh7cJrr6kh14UgYe1dBSWqSML6D1SQ7c2TsPbmtbeoIgpLqYJwy/wKg4oxXqc8BKvLBsqPzTfckPMg7ps6WKOwBF47mr+QEkUetjhXgnGGnIbaRW2aw3fnHJxp0UWjvHkUFsdecW+UQSRSezBwE0mjJleKITMfA/XmKViY134MIdxzrt7LQVsBkyxS7j+ZFUgqqS4W5s1XYVH2SjPYPGiOxBLGSRwSzu1RaVGY3arCwibEdeKwCTfcMh/HcbyiLD3RN5bbVV2IKizMZaB2Dc5EGqRLLVV5XNjGhQmCBlbzyia3QpXXxY0QUAtWk2xTWBYqWNgu6ZnTZKHSzryqiqaw/lyfNatBYWylMf3PCQtJKWJHFilVkB6ljKV6NedLJVkfAUuTzWdos2o7YlUYVK6DxYApP64mlRY7cbJZNYdb7jpg26vFgP+l64AtFOZsU8ed1d2q1XZopUoSqMPsJWIr0Q2EgqXydK0N+0p76h2ZyKXXH6SpEI3k1F/4VtgCnNK8c9kQAnboFQOj5kOZFzJSyshKfvTeHzjJ+JSrcG4vGHaN/Q6IaotjWkhYnLAGdOSce1p7VJTqw4MkNI+9NlgKcJLC6oJRUQaJHSRhFUE7btL+6QlbCidDdVagj0jtRmNQhU1T9c0NN7FgKYBRNv7d/9RgmVFOUZ9FYbbtY8MqgJmFheHdDjfayjEHClpsUNC2CBZUMFxl8aHYEhglzWvgxbCKWBTYMPLG5LzTvBloHmYW0Qp46ku9mK0JGnsHPW9ABSs3Uux0HNU8hBCepBccmGoVV2ywiaBPjeEuk+vi1PpsglU2CuDmeYZH5Kfflg+71yaT+09vfeCC1/xhuRkshXH9clU6LMGSdVgdloCAoGiXrA5LQEBQtEtWhyUgICjaJavDEhAQFO2S1WEJCAiK/gYDATh5tDER9wAAAABJRU5ErkJggg==" class="icono"/>
          <p>INVESTIGADORES Y COORDINADORES</p>
        </router-link>
        <router-link to="/mapas-riesgo" class="ejec">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAAAXNSR0IArs4c6QAABU1JREFUeF7tW21S3DAMjZNwjsJJCicBTtJyEuhJWE7S7TlI4q52HCYTok/LXpjJ/mEAx7Gfnp4kWxua/SNGIIhH7gObHSwFCXawdrAUCCiG7sz6TmDFGK/HcbwNIVyndf+Av4UQjvB7jPEt/TxeXV0dFHtzH1qdWQDEMAzXbdvenrD4pdwRAHgAAPu+f1E+mz28GlgA0jRNDwaAsE2egWvb9mlmYTYazATFwSoA0npLANqfrut+f2uwxnGEDWhdzbrn4qAVY9Y4js9N04Db1f48lWKZO1gpuj2HEEDApZ858h2T/vxrmuYcFWEC5VzwyLFt2ztvLXMFK+nTXyFCZ7eZpunApQSLCHp/EnUwwpxmUK9yB8wVrGEYXgUsyNaWYRgeQgighRxox67rboTGY4e5gSUEyk1PpFE2xnjo+/6ORUIwwAUsgZgfp2l65NxNsN5PQxJorwzLXrque7TMv3wmG6z39/fbtm1hsZsfT8sS74CSiQwqMcbH3Kw/G6xxHEHQN7VDC9Qc/aAc6vt+jowiQgiicLZ+ZYGVhBbyqa2PaHEL7YFItwW6KmpO04S6ZC67ssAaxzFiZp+m6Y7SKKlAr+Z/4WpBJn0RGRDbkxksppQhBZXTOcbv2NSDCTjmiJwDFqpVXdeh82YCNeNIAlaKXTlgYS6IWs4JqA/AqHSE0lNOIlzdkFpI27Y3WE1GaZwo5H0ehGoQwy6TK5qYRWTrqFYJjms+oh6kDYBLOm7myhr0ndg6tSnNbCMTWBhDqNDMsAq1tCBqouyiPIDSVTc3pOiNuSCTj4lKESrCYRpkWSslB2pmUSKNWYvaqNTCVA2IuRUFlkXk1WBZqE2URCJWzdYmQEddEXv3pcFSL1hbfhBBgtIt7IxNHRHVzMIWTEUYTNy11rWwmojc5cEiFqxm1ul4XbVgC7MwN9SyGqRAzSxP62rzHUveZElz3FIHKhpiqQOVkEpdkSmVNgMFFQ2rMMsSjrnClru24o6OMSNZDOuaZ6UyZLOIpljCXGigpwhMQgtdNuiFhEUy3MGyaIfwxOEYYzwfJ6e2I/ailjIQlpdptTK3NsR6GMiTSEExrTp84DZN1KOqZDgLLIollKUFlwpisDigvsx5FhNlyEvN9Cwcu5ibRjigkq6ijSnSenRtOXWeNU9ACDbbYyA4dqEYJkpkvV3QlJTOu7DkPWsENCwDNsGtsqQzxnKcI/F/M7Ng8hx2LReXtAyacH/C31MkhHQCWo8abb8VwarLXIUlsKCbBbtkNUUciYWpMRSrLFn78l1ZzOIy81LNIBhYXH+YVdizUoflYqkQLYlauUxarYXqDxMFBvcMfq03VH+B9hjGCh5XFuWyKisaStlVqr9zw2Boe2auVrm5oSDvgiFZUYhjG1Wke0pBlsBrrFvKHbl6U3pexhnEzQ3nF3EL9wZMcJKRLepuqcNWRs60K7r1lnJAebqfu2bNE3K5jofgC97RUA0qEpfbGuOmWYroeBZ87iiZSjy5ZltPnSrmhsuJBfplipCCfntXnaoClvBEQQUYB1QJnaoCFrxEeDIqAuzSQLmnDls6w11jpWdQDfME3CrsxaKhJ2BSoGqdbhSJhh6AcXnUzMhaQFVxw42SiPtS0vmL4oILDbcEV+qe1Zi1Slo5wLj1VweqOrOcADMntJwFuP9XZ9YSMC4T36g93b5oyQFTrdyRLkRzf1g64ZSs+WLMUpZGxUoYCUhV8yzJglKqANdqy+8cXkTIsfV+CWatdAzajO5DCG/ay1WJUXLGfCmwcjZS49kdLAXKO1g7WAoEFEN3Zu1gKRBQDP0P+xmCiOBcWfsAAAAASUVORK5CYII=" class="icono"/>
          <p>MAPAS</p>
        </router-link>
        <router-link to="/sistema-escucha" class="ejec">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA4pJREFUaEPtmu1RHDEMhq1OQidQSUIlgUoClUAnoRPlxNiMY/Rte28Obv8kw669eizplexbKN/sgm/GW67AX93jVw/v9DAi/iil/Cql/D79+1ZKeS6lPAEA/f+Q6xAPD6Aj2KHgW4EN0LOAbwEOgh4KvhR4EvQQ8CXACdDHSkfiZV1Lc3wKOAMKAA+NMDh+CXgKOGgo8T32oKNLg/NNgYeAg4aZoOcAdwHvBj0S3ARGROqM/ljKUu+roeuc4+Ox4EK/h7qWOjSxCoyIt6WUF4ehS0EnPX4HAK+SzRYwKapWOraCJsFVm7LAh4IGwZcD3wPAkyPMtz8i6MtlApNgWdtGRORSbjnw9nBGRKoKJJgkQOJe+UsAV1gqhXQRrAg9PNtSSE05S7S4GkwnFPc7ElQAEKF3AHN1+BUA7lYDC8a317BphIjUI5CN/TVVh+kM6u8w4RsA3KwENmDFiEJEso1s7K8bLe89rSWOcHDq3yTg2g6SIS5xy8LS+/GkWhHbzNayTuoOmw5WDcV2cxKW0xcz3TwephLRVFNUQgbWyj9u3jbGFEah6TDHeYBdKynURBZ6xrNddHCRZ3aBHmC3cHmgV8BK+XvaHqqC5crhRB5rOyyqqaOqusO48y67R9fEtI01PVyBuXzTyoW1rRzF1cy9foAQJa45vMBcA6LWYyO8e/tdhg7An8pRbUHFjX/Iw0pYqyLhgM7AstHjCWd3Dldg7kVm16VAh2EVsXLP5Qrp+iJOremWWQoYaLeBQyhLB4qmOodDWglr08tDhKRg6xxc7xyaz+1hw8vevvlWO1EcpXvwrqT86u5onDMErJQoqq8U2qZKalDSPeW4OOTdkGh1RV/KZVdoJ4G5UKap3LmbyuEOWgqv8IpbCyBs8mmYK42mQ7rL5XbQNs6ZMoQDV37mMbeB0kKGc9gR2unVd4oUPRYSqn7eNHD1tPZDW9rTxo7KrPtamkwBD/WVe08YOnOYZ+nAMg87RIweoZ9lCFz9+KyemEi6QPOk83YHcP+FHbfg6m+3jk3GEthUHVaaAwuahv7XoDi8+h4hKw/+p3N4XACHt9pHKTTU+mwprAFWPi8H7tSbYKTjHMuuba3qFuCuOWlfzlqA/f1l+cq9dBuwU8F7m1wfpURW7izADm8fAtrgt3u4X+XuM6Sf9e/mZ0azHh3HHwq82vjMfFfgzKpd0pirhy/JWxlb/wE6g0xbHnlMiQAAAABJRU5ErkJggg==" class="icono"/>
          <p>ESCUCHA SOCIAL Y ALERTAS DIGITALES</p>
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Home',
  created() {
  if (!this.$store.getters.sesionIniciada) {
    this.$router.push('/acceso');
  }
},

  methods: {
    cerrarSesion() {
    console.log("🛑 Saliendo de la sesión...");
    this.$store.dispatch("terminarSesion").then(() => {
      this.$router.push("/acceso"); // Redirigir después de cerrar sesión
    });
  }
  }
}
//data() {
//return {
  //  userProfileImage: '', // URL de la imagen de perfil obtenida desde la API
 // };
//},
//created() {
  // Lógica de carga de la imagen desde la API
 // this.userProfileImage = this.$store.getters.userProfileImage || '@/assets/perfil.png'; 
  // Si no hay imagen de perfil en la API, usa una imagen por defecto
//}
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  background-image: 
    linear-gradient(to bottom, rgba(23, 38, 61, 0.6), rgba(23, 38, 61, 5)),
    url('@/assets/logo_bg.png'); /* Degradado superpuesto sobre la imagen */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: white;
}

.home-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); /* Línea divisora */
}

.logo {
  width: 150px;
}

h1 {
  font-size: 17px;
  margin: 0;
  font-size: bolder;
}

.tlahuicole{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  size: 20px ;
}

.tlahuicole img {
  width: 500px;
}

.logout-button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}
.logout-button:hover {
  background-color: #01bbd2;
  transform: scale(1.05);
}
.logout-button img {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}
.profile-container {
  display: flex;
  align-items: center; /* Alinea verticalmente la imagen y el botón */
}
.profile {
  display: flex;
  align-items: right;
  justify-content: right;
  margin-right: 10px; /* Espacio entre la imagen y el botón de cierre de sesión */
}

.profile img {
  width: 50px; /* Tamaño ajustable según necesidad */
  height: 50px; /* Tamaño ajustable según necesidad */
  border-radius: 50%; /* Hace la imagen circular */
  object-fit: cover; /* Asegura que la imagen se ajuste dentro del círculo sin deformarse */
  border: 2px solid white; /* Añade un borde para resaltar la imagen */
}


.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.ejes {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-bottom: 80px;
}

.eje {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  padding: 10px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  text-align: center;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
}

.eje:hover {
  background-color: rgba(1, 187, 210);
  transform: scale(1.05);
}
.ejec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  padding: 10px;
  border: 2px solid rgba(255, 2, 0.6);
  border-radius: 10px;
  text-align: center;
  color: inherit;
  text-decoration: none;
  position: relative; /* Necesario para usar el pseudo-elemento */
  overflow: hidden;
  animation: float 2s ease-in-out infinite; /* Animación de flotación */
}

/* Efecto al pasar el ratón */
.ejec:hover {
  background-color: red;
  transform: scale(1.05);
}

/* Pseudo-elemento para el texto "En Construcción" */
.ejec::after {
  content: "⚠️En Construcción⚠️";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.8); /* Fondo rojo semitransparente */
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Mostrar el texto al pasar el ratón */
.ejec:hover::after {
  opacity: 1;
}

/* Animación de flotación */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Flotación hacia arriba */
  }
}

.icono {
  font-size: 2.5rem;
  margin-bottom: 10px;
}
p{
  font-size: 14px;
}
/* Contenedor del botón flotante */
.floating-button-container {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
  flex-direction: column;
}
.bot-flot{
  align-items: center;
  flex-direction: column;
}

/* Contenedor de los botones flotantes */
.floating-button-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los botones */
  gap: 20px; /* Espaciado entre botones */
}

/* Botón flotante principal */
.floating-button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: bolder;
  color: white;
  background: linear-gradient(135deg, #01bbd2, #17263d);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(1, 187, 210, 0.5);
  animation: pulse 2s infinite;
  text-decoration: none;
  text-align: center;
  width: 300px; /* Ajusta el ancho */
  margin-top: -15px;
}

.floating-button:hover {
  background: linear-gradient(135deg, #17263d, #01bbd2);
}

/* Botón flotante secundario (con cambios de tamaño y color) */
.floating-button2 {
  display: inline-block;
  padding: 10px 20px; /* Tamaño diferente */
  font-size: 14px; /* Letra más grande */
  font-weight: bold;
  color: white;
  background: linear-gradient(135deg, #ff4e50, #fc913a); /* Nuevo color */
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(255, 78, 80, 0.5);
  animation: glow 2s infinite alternate;
  text-decoration: none;
  text-align: center;
  width: 150px; /* Un poco más ancho */
}

.floating-button2:hover {
  background: linear-gradient(135deg, #fc913a, #ff4e50);
}

/* Animación de latido para el botón principal */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(1, 187, 210, 0.5);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 35px rgba(1, 187, 210, 0.8);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(1, 187, 210, 0.5);
  }
}

/* Animación de latido para el botón secundario */
@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 78, 80, 0.5);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 78, 80, 0.9);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 78, 80, 0.5);
  }
}

/* Responsividad para pantallas medianas (tablets) */
@media (max-width: 1300px) {
  .home-header {
    flex-direction: column;
    text-align: center;
  }
  .logo {
    width: 80px;
    margin-bottom: 10px;
  }
  .h1 {
    font-size: 1.2rem;
  }
  .ejes {
    gap: 50px;
    padding: 10px;
  }
  .eje {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    padding: 10px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    text-align: center;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
  }
  .eje:hover {
    background-color: rgba(1, 187, 210);
    transform: scale(1.05);
  }
  .ejec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    padding: 10px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    text-align: center;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
  }
  .ejec:hover {
    background-color: Red;
    transform: scale(1.05);
  }
}

/* Responsividad para pantallas pequeñas (móviles) */
@media (max-width: 480px) {
  body {
    margin: 0;
    background-image: 
      linear-gradient(to bottom, rgba(23, 38, 61, 0.8), rgba(23, 38, 61, 1)),
      url('@/assets/inicio.fw.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Hace que el fondo se mantenga en su lugar */
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .home-container {
    background: none; /* Evita que el fondo de Home.vue se repita */
    min-height: 100vh;
    overflow-y: auto; /* Permite el desplazamiento del contenido */
    z-index: 1;
    position: relative;
  }

  /* Otros estilos */
  .home-header {
    flex-direction: column;
    padding: 10px;
  }

  .logo {
    width: 60px;
  }

  h1 {
    font-size: 1rem;
  }

  .logout-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .ejes {
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
  }

  .eje {
    width: 200px;
    height: 200px;
  }

  .icono {
    font-size: 1rem;
  }

  .tlahuicole {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tlahuicole img {
    width: 200px;
    max-width: 100%;
  }
  .logout-button {
    padding: 10px 20px;
    background-color: #ff4d4d;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    align-items: right;
  }
}

</style>
