<template>
  <div class="dashboard-content">
    <!-- Sección de Encabezado -->
    <div class="header-section">
      <div class="title">
        <div class="logo-section">
          <img src="@/assets/logo_tlah.png" class="logo" />
        </div>
      </div>
      <div>
      <p class="texto">CONCENTRADO REPORTES</p> 
      <p>Reportes totales: {{ totalReportes }}</p>
    </div>
      <div class="search-section">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Buscar municipio..."
          class="search-input"
        />
        <i class="fas fa-search search-icon"></i>
      </div>
    </div>

    <!-- Navegación entre municipios -->
    <div class="navigation-buttons" v-if="!searchQuery">
      <button @click="prevPage" :disabled="currentPage === 0">Anterior</button>
      <button @click="nextPage" :disabled="currentPage >= maxPage - 1">Siguiente</button>
    </div>

    <!-- Sección de Cards -->
    <div class="cards-section">
      <div
        v-for="(municipio, index) in filteredAndPaginatedMunicipios"
        :key="index"
        class="card"
        :class="{ highlighted: municipioSeleccionado === municipio.nombre }"
        @click="seleccionarMunicipio(municipio)"
      >
        <h3>{{ municipio.nombre }}</h3>
        <p class="txt">{{ municipio.distrito }}</p>
        <p>Reportes totales: {{ municipio.reportesTotales }}</p>
        <div class="alert-status">
          <div class="status" v-for="(alerta, key) in municipio.alertas" :key="key">
            <span :class="getPriorityClass(key)" class="priority-number">{{ alerta }}</span>
            <p>{{ key }}</p>
          </div>
        </div>
        
      </div>
    </div>

    <!-- Tabla de Reportes -->
    <div class="table-section">
      <h2 v-if="municipioSeleccionado">Lista de reportes de {{ municipioSeleccionado }}</h2>
      <div class="table-responsive">
        <table class="table" v-if="reportesSeleccionados.length">
          <thead>
            <tr>
              <th>IDENTIFICADOR</th>
              <th>TÍTULO</th>
              <th>FECHA CREACIÓN</th>
              <th>PRIORIDAD</th>
              <th>CREADO POR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(reporte, index) in reportesSeleccionados" :key="index">
              <td>{{ reporte.identificador }}</td>
              <td>{{ reporte.titulo }}</td>
              <td>{{ reporte.fecha }}</td>
              <td :class="getPriorityClass(reporte.prioridad)">{{ reporte.prioridad }}</td>
              <td>{{ reporte.creadoPor }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else>No hay reportes disponibles para este municipio.</p>
      </div>
    </div>
  </div>
</template>

<script>
import municipios from "@/assets/municipios_60_completos.json";

export default {
  data() {
    return {
      municipios: municipios, // Todos los municipios disponibles
      reportesSeleccionados: [],
      municipioSeleccionado: "",
      currentPage: 0,
      itemsPerPage: 4,
      searchQuery: "", // Para almacenar el término de búsqueda
    };
  },
  computed: {
    filteredMunicipios() {
      // Filtra todos los municipios basándose en el término de búsqueda
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.municipios.filter((municipio) =>
        municipio.nombre.toLowerCase().includes(searchQueryLower)
      );
    },
    filteredAndPaginatedMunicipios() {
      if (this.searchQuery) {
        return this.filteredMunicipios;
      }
      const start = this.currentPage * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredMunicipios.slice(start, end);
    },
    maxPage() {
      return Math.ceil(this.filteredMunicipios.length / this.itemsPerPage);
    },
    totalReportes() {
      return this.municipios.reduce((acc, municipio) => acc + municipio.reportesTotales, 0);
    },
  },
  methods: {
    seleccionarMunicipio(municipio) {
      this.municipioSeleccionado = municipio.nombre;
      this.reportesSeleccionados = municipio.reportes;
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.maxPage - 1) {
        this.currentPage++;
      }
    },
    getPriorityClass(prioridad) {
      return {
        Baja: "priority-low",
        Media: "priority-medium",
        Alta: "priority-high",
      }[prioridad];
    },
  },
  mounted() {
    // Seleccionar el primer municipio por defecto
    if (this.municipios.length > 0) {
      this.seleccionarMunicipio(this.municipios[0]);
    }
  },
};
</script>

  <style scoped>
  p{
    text-align: center;
  }
  .texto{
    font-weight: bold;
  }
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .navigation-buttons button {
    padding: 10px;
    background-color: #1e2a38;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .navigation-buttons button:disabled {
    background-color: #444;
    cursor: not-allowed;
  }

  .navigation-buttons button:hover{
background-color: #00bcd4;
  }
  .logo{
    width: 280px;
    margin-top: -20px;
  }
  .txt{
    font-size:11px;
  }
  h3{
    font-size:17px;
    font-weight: bold;
  }
  .dashboard-content {
    max-width: 80%;
    margin: 0 auto;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-left: 0px;
    position:fixed;
    overflow-y: auto; /* Permite el desplazamiento vertical si el contenido es mayor al viewport */
    max-height: 95vh; /* Altura máxima del viewport */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaciado entre secciones */
  }
  
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .title h1 {
    margin: 0;
  }
  
  .search-section {
    position: relative;
  }
  
  .search-input {
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
  }
  
  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .cards-section {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #17263d;
    padding: 15px;
    border-radius: 10px;
    flex: 1;
    color: #fff;
    width: 300px;
    cursor: pointer;
   
  }
  
  .card.highlighted {
    background-color: #00bcd4;
  }
  
  .alert-status {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .status {
    text-align: center;
  }
  
  .table-wrapper {
    width: 100%; /* Tabla ocupa todo el ancho del contenedor */
    overflow-x: auto; /* Desplazamiento horizontal solo si es necesario */
  }
  
  .table {
    width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
    border-collapse: collapse; /* Limpia los bordes internos */
  }
  
  .table th,
  .table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table th {
    background-color: #17263d; /* Fondo para los encabezados */
    color: white; /* Texto blanco */
    position: sticky; /* Encabezados fijos */
    top: 0;
    z-index: 1;
  }
  
  .table td {
    background-color: #1e2a38; /* Fondo para las filas */
    color: white;
  }
  
  .priority-low {
    color: #00c853; /* Verde */
    font-weight: bold;
  }
  
  .priority-medium {
    color: #ffab00; /* Amarillo */
    font-weight: bold;
  }
  
  .priority-high {
    color: #d50000; /* Rojo */
    font-weight: bold;
  }
  
  .priority-number {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  
  .priority-low {
    background-color: #00c853; /* Verde */
  }
  
  .priority-medium {
    background-color: #ffab00; /* Amarillo */
  }
  
  .priority-high {
    background-color: #d50000; /* Rojo */
  }
  
  .priority-default {
    background-color: #607d8b; /* Gris */
  }
  
  </style>
  