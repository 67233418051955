import axios from "axios";

const API_GESTOR_URL = "https://api.gestordigital.mx/api";

const apiGestor = axios.create({
  baseURL: API_GESTOR_URL,
  headers: { "Content-Type": "application/json" },
});

apiGestor.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("gestor_token");
    
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        if (Date.now() >= payload.exp * 1000) {
          console.warn("Token expirado. Eliminando del localStorage.");
          localStorage.removeItem("gestor_token");
          return Promise.reject(new Error("Token expirado"));
        }
      } catch (e) {
        console.error("Error decodificando el token:", e);
        localStorage.removeItem("gestor_token");
        return Promise.reject(new Error("Token inválido"));
      }
      
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn("Token no encontrado en localStorage.");
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default apiGestor;
