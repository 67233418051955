<template>
  <div>
    <h1>Listado de Stakeholders</h1>

    <!-- Barra de búsqueda -->
    <input type="text" placeholder="Buscar..." v-model="searchQuery" @input="filterList" />

    <!-- Tabla de elementos -->
    <table class="table">
      <thead>
        <tr>
          <th>Foto</th>
          <th>Nombre</th>
          <th>Teléfono</th>
          <th>Correo</th>
          <th>Domicilio</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredItems" :key="item.id">
          <td><img :src="item.foto" alt="Foto" class="foto" /></td>
          <td>{{ item.nombre }}</td>
          <td>{{ item.telefono }}</td>
          <td>{{ item.correo }}</td>
          <td>{{ item.domicilio }}</td>
          <td>
            <button class="btno" @click="viewDetails(item.id)">👁️</button>
            <button class="btned" @click="editItem(item)">✍🏼</button>
            <button class="btndel" @click="deleteItem(item.id)">🗑️</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Barra de Paginación -->
    <div class="pagination">
      <button @click="loadPage(prevPageUrl)" :disabled="!prevPageUrl">
        ⟨ Anterior
      </button>
      <span>Página {{ currentPage }} de {{ lastPage }}</span>
      <button @click="loadPage(nextPageUrl)" :disabled="!nextPageUrl">
        Siguiente ⟩
      </button>
    </div>

    <!-- Modal de Ficha de Perfil -->
    <div v-if="selectedDetails" class="profile-modal">
      <div class="profile-modal-content">
        <span class="close" @click="selectedDetails = null">&times;</span>

        <!-- Contenido desplazable -->
        <div class="profile-scrollable">
          <!-- Cabecera -->
          <div class="profile-header">
            <img :src="selectedDetails.foto" alt="Foto del perfil" class="profile-picture" />
            <h2>{{ selectedDetails.nombre }}</h2>
            <p v-if="selectedDetails.titulo">{{ selectedDetails.titulo }}</p>
            <p v-else>Sin título</p>
            <p v-if="selectedDetails.alias">{{ selectedDetails.alias }}</p>
            <p v-else>Sin alias</p>
          </div>

          <!-- Información General -->
          <div class="profile-details">
            <h3>Información General</h3>
            <p>
              <strong>Fecha de Nacimiento:</strong>
              {{ selectedDetails.fecha_nacimiento || "No disponible" }}
            </p>
            <p>
              <strong>Celular:</strong>
              {{ selectedDetails.celular || "No disponible" }}
            </p>
            <p>
              <strong>Teléfono:</strong>
              {{ selectedDetails.telefono || "No disponible" }}
            </p>
            <p>
              <strong>Correo:</strong>
              {{ selectedDetails.correo || "No disponible" }}
            </p>
            <p>
              <strong>Domicilio:</strong>
              {{ selectedDetails.domicilio || "No disponible" }}
            </p>
          </div>

          <!-- Mapa -->
          <div v-if="selectedDetails.coordenadas_domicilio" class="map-container">
            <h3>Ubicación</h3>

            <iframe
              :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyCCh6AQwwBKFeQrmmtbPsp-MDh_gWctzNc&q=${selectedDetails.coordenadas_domicilio}`"
              frameborder="0" style="border: 0; width: 100%; height: 300px" allowfullscreen>
            </iframe>
          </div>

          <!-- Afinidades Políticas -->
          <div v-if="selectedDetails.afinidades_politicas.length" class="political-affinities">
            <h3>Afinidades Políticas</h3>
            <div class="party" v-for="afinidad in selectedDetails.afinidades_politicas" :key="afinidad.id">
              <img :src="afinidad.partido_politico.logo" alt="Logo del Partido" class="party-logo" />
              <p>
                {{ afinidad.partido_politico.nombre }} -
                {{ afinidad.afinidad_politica }}
              </p>
            </div>
          </div>

          <!-- Archivos Adjuntos -->
          <div v-if="selectedDetails.archivos.length" class="attachments">
            <h3>Archivos Adjuntos</h3>
            <ul>
              <li v-for="archivo in selectedDetails.archivos" :key="archivo.id">
                <a :href="`https://api.gestordigital.mx/${archivo.ruta}`" target="_blank">{{ archivo.nombre }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import apiGestor from "@/api/apiGestor"; // Importamos la API configurada

export default {
  data() {
    return {
      items: [], // Lista de stakeholders
      filteredItems: [], // Lista filtrada
      searchQuery: "", // Texto de búsqueda
      currentPage: 1, // Página actual
      lastPage: null, // Última página
      nextPageUrl: null, // URL siguiente
      prevPageUrl: null, // URL anterior
      selectedDetails: null, // Almacena los detalles del stakeholder seleccionado
      showDetailsModal: false, // Controla la visibilidad del modal
    };
  },
  methods: {
    async loadItems(url = "/stakeholders") {
      console.log(`📡 Cargando datos desde: ${url}`);
      const token = localStorage.getItem("gestor_token");

      if (!token) {
        console.warn("⚠️ No hay un token válido en localStorage.");
        alert("Por favor, inicia sesión nuevamente.");
        this.$router.push("/acceso");
        return;
      }

      try {
        const response = await apiGestor.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log("✅ Datos obtenidos:", response.data);
        this.items = response.data.data || [];
        this.filteredItems = this.items;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.nextPageUrl = response.data.next_page_url;
        this.prevPageUrl = response.data.prev_page_url;
      } catch (error) {
        console.error("❌ Error al cargar los datos:", error);

        if (error.response && error.response.status === 401) {
          console.warn("⏳ Token inválido. Redirigiendo...");
          localStorage.removeItem("gestor_token");
          this.$router.push("/acceso");
        }
      }
    },

    async viewDetails(id) {
      console.log(`📡 Cargando detalles del ID: ${id}`);
      const token = localStorage.getItem("gestor_token");

      if (!token) {
        console.warn("⚠️ Token no disponible.");
        return;
      }

      try {
        const response = await apiGestor.get(`/stakeholders/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log("✅ Detalles obtenidos:", response.data);
        this.selectedDetails = response.data;
        this.showDetailsModal = true; // Mostrar modal con los detalles
      } catch (error) {
        console.error("❌ Error al cargar los detalles:", error);
      }
    },

    closeDetails() {
      this.showDetailsModal = false;
      this.selectedDetails = null;
    },

    filterList() {
      const query = this.searchQuery.toLowerCase();
      this.filteredItems = this.items.filter(
        (item) =>
          item.nombre.toLowerCase().includes(query) ||
          item.telefono.includes(query) ||
          item.correo.toLowerCase().includes(query) ||
          item.domicilio.toLowerCase().includes(query) ||
          item.id.toString().includes(query)
      );
    },

    loadPage(url) {
      if (url) {
        this.loadItems(url);
      }
    },
  },
  mounted() {
    if (this.$store.getters.sesionIniciada) {
      this.loadItems();
    } else {
      console.warn("⚠️ Sesión no iniciada. Redirigiendo a /acceso.");
      this.$router.push("/acceso");
    }
  },
};
</script>





<style scoped>
.profile-modal-content {
  background-color: #17263d;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  max-height: 90%;
  overflow-y: auto;
  /* Habilita el desplazamiento vertical */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.profile-scrollable {
  max-height: calc(100vh - 60px);
  /* Ajusta el contenido al 90% de la altura de la ventana */
}

.map-container iframe {
  border: 0;
  width: 100%;
  height: 300px;
}

.party {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.party-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.attachments ul {
  list-style: none;
  padding: 0;
}

.attachments li {
  margin-bottom: 10px;
}

.btno {
  background-color: green;
  border-radius: 30%;
  width: 50px;
  margin: 5px;
}

.btned {
  background-color: blue;
  border-radius: 30%;
  width: 50px;
  margin: 5px;
}

.btndel {
  background-color: red;
  border-radius: 30%;
  width: 50px;
  margin: 5px;
}

.profile-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.profile-modal-content {
  background-color: #17263d;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-header .profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.profile-details {
  margin-top: 20px;
}

.profile-details h3 {
  margin-bottom: 10px;
}

.profile-details p {
  margin: 5px 0;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  background-color: #17263d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* General */
.stakeholder-list {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 50%;
}

input[type="text"] {
  flex: 1 1 auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.color-selector {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.table {
  width: auto;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  font-size: 11px;
}

.table th {
  background-color: #17263d;
  color: white;
}

.foto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.btn {
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  margin: 0 2px;
}

.btn-add {
  background-color: #4caf50;
  color: white;
}

.btn-add:hover {
  background-color: white;
  color: black;
}

.btn-edit {
  background-color: #ffa726;
  color: white;
}

.btn-delete {
  background-color: #f44336;
  color: white;
}

.btn-update {
  background-color: #2196f3;
  color: white;
}

.filter {
  width: 50%;
}

/* Responsivo */
@media (max-width: 768px) {

  .table th,
  .table td {
    font-size: 12px;
    padding: 5px;
  }

  .toolbar {
    flex-direction: column;
  }

  .btn {
    font-size: 12px;
    padding: 5px;
  }
}

.table thead {
  background-color: #007bff;
  /* Cambia este color al que desees */
  color: white;
  /* Cambia el color del texto si es necesario */
}
</style>
