import { render, staticRenderFns } from "./PanelControl.vue?vue&type=template&id=5b5cb290&scoped=true"
import script from "./PanelControl.vue?vue&type=script&lang=js"
export * from "./PanelControl.vue?vue&type=script&lang=js"
import style0 from "./PanelControl.vue?vue&type=style&index=0&id=5b5cb290&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5cb290",
  null
  
)

export default component.exports