<template>
    <div class="estado-reportes-container">
      <div class="header">
        <h3>ESTADO DE LOS REPORTES ASIGNADOS A INVESTIGACIÓN</h3>
      </div>
      <div class="estado-list">
        <!-- Pendientes -->
        <div class="estado-item">
          <div class="icono">
            <img src="@/assets/icons/pendientes.png" alt="Pendientes" />
          </div>
          <div class="descripcion">PENDIENTES</div>
          <div class="valor">{{ valores.pendientes }}</div>
        </div>
        <div class="estado-separator"></div>
  
        <!-- Progreso -->
        <div class="estado-item">
          <div class="icono">
            <img src="@/assets/icons/progreso.png" alt="Progreso" />
          </div>
          <div class="descripcion">PROGRESO</div>
          <div class="valor">{{ valores.progreso }}</div>
        </div>
        <div class="estado-separator"></div>
  
        <!-- Concluidos -->
        <div class="estado-item">
          <div class="icono">
            <img src="@/assets/icons/concluidos.png" alt="Concluidos" />
          </div>
          <div class="descripcion">CONCLUIDOS</div>
          <div class="valor">{{ valores.concluidos }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ReportesInvestigacionChart",
    data() {
      return {
        valores: {
          pendientes: 5,
          progreso: 14,
          concluidos: 243,
        },
      };
    },
  };
  </script>
  
  <style scoped>
  .estado-reportes-container {
    width: 380px;
    max-width: 400px;
    background-color: #1e2a38;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    transform: translate(820px);
    margin-top: -480px;
  }
  
  .estado-reportes-container .header {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .estado-reportes-container .header h3 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    color: #33b5e5;
  }
  
  .estado-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .estado-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .icono img {
    width: 40px;
    height: 40px;
  }
  
  .descripcion {
    flex: 1;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  
  .valor {
    font-size: 20px;
    font-weight: bold;
  }
  
  .estado-separator {
    height: 2px;
    background: linear-gradient(to right, transparent, #33b5e5, transparent);
    margin: 5px 0;
  }
  </style>
  