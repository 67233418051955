<template>
  <div class="container">
    <h1>Delegaciones IMSS :</h1>
    <iframe 
    width="100%" 
    height="600px" 
    :src="dashboardUrl" 
    frameborder="0" 
    allowFullScreen="true">
  </iframe>
  </div>
</template>

<script>
export default {
  name: "IMSS",
  data() {
    return {
      dashboardUrl: "https://app.powerbi.com/view?r=eyJrIjoiZjMyODY1YmUtZmVhYi00Zjk3LTlkOTctM2U2NzEyOTVkMDQ4IiwidCI6ImFlNzJhZmQzLTkyZDktNGY0MS1hOGE2LTA5ZTZiOWIxOGIwYiJ9"
    };
  }
};
</script>

<style scoped>
/* Estilos generales */
.container {
 
  flex-direction: column;
  align-items: center;
  width: 150%;
  border:none;
  padding-left: var(--sidebar-width, 120px); /* Espacio para el sidebar */
  transition: padding-left 0.3s ease-in-out;

}

/* Ajuste del iframe para hacerlo responsive */
iframe {
  width: calc(100% - var(--sidebar-width, 120px));
  height: 85vh;
  border: none;
  max-width: 100%;
  transition: width 0.3s ease-in-out;

}

/* Cuando el sidebar se contrae */
body.sidebar-collapsed .container {
  --sidebar-width: 60px; /* Ajuste cuando el sidebar está contraído */
}

/* Responsividad para pantallas más pequeñas */
@media (max-width: 768px) {
  .container {
    --sidebar-width: 0px; /* En móviles, sidebar oculto */
    padding-left: 0;
  }
  iframe {
    width: 100%;
  }
}
</style>
