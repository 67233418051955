<template>
    <div class="latentes-riesgos-root">
      <!-- Encabezado del Dashboard -->
      <div class="encabezado">
        <h1>Latentes de Riesgos</h1>
      </div>
  
      <!-- Contenedor Principal de Gráficos -->
      <div class="contenedor-graficos">
        <!-- Fila 1 -->
        <div class="fila ">
          <RiesgoDistritosChart class="grafico" />
          <MapaRiesgoChart class="grafico" />
          <RedVinculosChart class="grafico" />
        </div>
  
        <!-- Fila 2 -->
        <div class="fila ">
          <EventosAfectacionChart class="grafico" />
          <SemaforoProblemasChart class="grafico" />
        </div>
  
        <!-- Fila 3 -->
        <div class="fila ">
          <TopDesgloseEventosChart class="grafico"/>
          <DesgloseSemaforoChart class="grafico"/>
        </div>
        <ReportesInvestigacionChart class="grafico" />
      </div>
    </div>
  </template>
  
  <script>
  // Importamos los componentes desde charts
  import RiesgoDistritosChart from "@/components/charts/RiesgoDistritosChart.vue";
  import MapaRiesgoChart from "@/components/charts/MapaRiesgoChart.vue";
  import RedVinculosChart from "@/components/charts/RedVinculosChart.vue";
  import EventosAfectacionChart from "@/components/charts/EventosAfectacionChart.vue";
  import SemaforoProblemasChart from "@/components/charts/SemaforoProblemasChart.vue";
  import TopDesgloseEventosChart from "@/components/charts/TopDesgloseEventos.vue";
  import DesgloseSemaforoChart from "@/components/charts/DesgloseSemaforoChart.vue";
  import ReportesInvestigacionChart from "@/components/charts/ReportesInvestigacionChart.vue";
  
  
  export default {
    name: "LatentesRiesgos",
    components: {
      RiesgoDistritosChart,
      MapaRiesgoChart,
      RedVinculosChart,
      EventosAfectacionChart,
      SemaforoProblemasChart,
      TopDesgloseEventosChart,
      DesgloseSemaforoChart,
      ReportesInvestigacionChart,
    },
  };
  </script>
  
  <style scoped>
  /* Estilos generales */
  .latentes-riesgos-root {
    padding: 20px;
    color: white; 
  }
  
  .encabezado {
    text-align: center;
    margin-bottom: 15px;
  }
  .encabezado h1{
    font-size: 25px;
  }
  
  .contenedor-graficos {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .fila {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
/* Asegura que cada chart tenga el mismo tamaño relativo */
.fila-1 > * {
    flex: 1; /* Todos los charts ocupan el mismo espacio */
    max-width: 32%; /* Limita el ancho para que no se desborde */
  }
  
  .fila-2 > * {
    flex: 1;
    width: 900px;
    z-index: 3;
  }
  .fila-3 > * {
    flex: 1;
    width: 900px;
    z-index: 3;
  }
  .fila > * {
    position: relative;
    overflow: hidden;
  }
  
  .grafico {
    flex: 1;
    background-color: #2e3b4e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  </style>
  