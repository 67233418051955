<template>
    <div class="top-desglose-eventos">
      <!-- Título y Navegación -->
      <div class="header">
        <h3>TOP DESGLOSE EVENTOS AFECTACIÓN GOBERNANZA</h3>
        <div class="navegacion">
          <button @click="cambiarMes('prev')">❮</button>
          <span>{{ mesSeleccionado }}</span>
          <button @click="cambiarMes('next')">❯</button>
          <button @click="cambiarEvento('prev')">❮</button>
          <span>{{ eventoSeleccionado }}</span>
          <button @click="cambiarEvento('next')">❯</button>
        </div>
      </div>
  
      <!-- Contenedor de la tabla -->
      <div class="tabla-container">
        <table>
          <thead>
            <tr>
              <th>MUNICIPIO</th>
              <th>TIPO</th>
              <th>COORD.</th>
              <th>RIESGO</th>
              <th>JUD.</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="evento in eventosFiltrados" :key="evento.id">
              <td>{{ evento.municipio }}</td>
              <td>{{ evento.tipo }}</td>
              <td>
                <img src="@/assets/icons/marker.png" alt="Coordenada" />
              </td>
              <td :style="{ backgroundColor: getColor(evento.nivelRiesgo) }">
                {{ evento.nivelRiesgo }}
              </td>
              <td>{{ evento.judicializacion }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    name: "TopDesgloseEventos",
    data() {
      return {
        meses: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        eventos: {
          Enero: [
            { id: 1, municipio: "Huamantla", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 2, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "ALTO", judicializacion: "DEMANDA" },
            { id: 3, municipio: "Apizaco", tipo: "VIOLENCIA", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 4, municipio: "El Carmen", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 5, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "ALTO", judicializacion: "DEMANDA" },
          ],
          Febrero: [
            { id: 6, municipio: "Chiautempan", tipo: "ROBO", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 7, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 8, municipio: "Tlaxcala", tipo: "HOMICIDIO", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 9, municipio: "Apetatitlán", tipo: "EXTORSIÓN", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 10, municipio: "Apizaco", tipo: "VIOLACIÓN", nivelRiesgo: "ALTO", judicializacion: "DEMANDA" },
          ],
          Marzo: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Abril: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Mayo: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Junio: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Julio: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Agosto: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Septiembre: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Octubre: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Noviembre: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],
          Diciembre: [
            { id: 11, municipio: "El Carmen", tipo: "HOMICIDIO", nivelRiesgo: "MÁXIMO", judicializacion: "SIN DEMANDA" },
            { id: 12, municipio: "Tlaxcala", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "PROCESADO" },
            { id: 13, municipio: "Apizaco", tipo: "EXTORSIÓN", nivelRiesgo: "MÁXIMO", judicializacion: "DEMANDA" },
            { id: 14, municipio: "Huamantla", tipo: "VIOLENCIA", nivelRiesgo: "ALTO", judicializacion: "SIN DEMANDA" },
            { id: 15, municipio: "Apetatitlán", tipo: "ROBO", nivelRiesgo: "MODERADO", judicializacion: "SIN DEMANDA" },
          ],

        },
        mesSeleccionado: "Enero",
      tiposEventos: ["TODOS", "HOMICIDIO", "ROBO", "VIOLENCIA", "EXTORSIÓN", "VIOLACIÓN"],
      eventoSeleccionado: "TODOS",
    };
  },
  computed: {
    eventosFiltrados() {
      if (this.eventoSeleccionado === "TODOS") {
        return this.eventos[this.mesSeleccionado];
      }
      return this.eventos[this.mesSeleccionado].filter(
        (evento) => evento.tipo === this.eventoSeleccionado
      );
    },
  },
  methods: {
    cambiarMes(direccion) {
      const indiceActual = this.meses.indexOf(this.mesSeleccionado);
      if (direccion === "prev") {
        this.mesSeleccionado = this.meses[(indiceActual - 1 + this.meses.length) % this.meses.length];
      } else if (direccion === "next") {
        this.mesSeleccionado = this.meses[(indiceActual + 1) % this.meses.length];
      }
    },
    cambiarEvento(direccion) {
      const indiceActual = this.tiposEventos.indexOf(this.eventoSeleccionado);
      if (direccion === "prev") {
        this.eventoSeleccionado = this.tiposEventos[(indiceActual - 1 + this.tiposEventos.length) % this.tiposEventos.length];
      } else if (direccion === "next") {
        this.eventoSeleccionado = this.tiposEventos[(indiceActual + 1) % this.tiposEventos.length];
      }
    },
    getColor(nivelRiesgo) {
      const colores = {
        MÁXIMO: "#FF0000",  
        ALTO: "#FFA500",
        MODERADO: "#FFFF00",
        MENOR: "#00FF00",
      };
      return colores[nivelRiesgo] || "#FFFFFF";
    },
  },
};  
  </script>

  <style scoped>
  .top-desglose-eventos {
    background-color: #1e2a38;
    padding: 20px;
    border-radius: 8px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 395px;
    max-width: 410PX;
    height: 250px;
    overflow: hidden;
    margin-top: -250px;
    margin-left: -25px;
    font-size: 12px;
  }
  
  .top-desglose-eventos .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .top-desglose-eventos .header h3 {
    font-size: 0.9rem;
    font-weight: bold;
    margin: 0;
  }
  
  .top-desglose-eventos .navegacion {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .top-desglose-eventos .navegacion button {
    background-color: #2a3b4e;
    border: none;
    color: white;
    font-size: 10px;
    padding: 3px 6px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .top-desglose-eventos .navegacion button:hover {
    background-color: #33b5e5;
  }
  
  .tabla-container {
    width: 390PX;
    height: calc(100% - 40px);
    overflow-y: auto;
  }
  
  table {
    width: 400PX;
    border-collapse: collapse;
    font-size: 10px;
  }
  
  th, td {
    padding: 5px;
    text-align: center;
  }
  
  th {
    background-color: #2a3b4e;
  }
  
  td {
    background-color: #2e3b4e;
  }
  
  td img {
    width: 15px;
    height: 15px;
  }
  
</style>