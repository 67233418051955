<template>
    <div class="eventos-container">
      <div class="header">
        <h3>Semaforo Problemas Presentes en Tlaxcala </h3>
      </div>
      <!-- Línea degradada de fondo -->
      <div class="eventos-linea-degradada"></div>
      <div class="eventos-list">
        <div
          class="evento"
          v-for="evento in eventos"
          :key="evento.titulo"
          :style="{ '--evento-color': evento.color }"
        >
          <div class="evento-numero">{{ evento.numero }}</div>
          <div class="evento-titulo">{{ evento.titulo }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "EventosAfeccionChart",
    data() {
      return {
        eventos: [
          { titulo: "Seguimiento", numero: 59, color: "#95A5A6" },
          { titulo: "Menor", numero: 22, color: "#2ECC71" },
          { titulo: "Moderado", numero: 34, color: "#F1C40F" },
          { titulo: "Alto", numero: 15, color: "#E67E22" },
          { titulo: "Máximo", numero: 5, color: "#E74C3C" },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* Contenedor principal */
  .eventos-container {
    background-color: #1e2a38;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    flex: 1; /* Permite que ocupe el espacio disponible */
    height: auto; /* Ajusta automáticamente su tamaño */
    position: relative; /* Evita que afecte a otros elementos */
    margin-left: -350px;
    width: 350px;
    max-width: 400px;
    transform: translate(-415px, -250px);
  
    
    
  }
  
  /* Línea degradada de fondo */
  .eventos-linea-degradada {
    position: absolute;
    top: 55%;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(
      to right,
      #95A5A6,
      #2ECC71,
      #F1C40F,
      #E67E22,
      #E74C3C
    );
    transform: translateY(-50%);
    z-index: 1;
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    z-index: 2;
    position: relative;
  }
  
  .header h3 {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
  }
  
  
  /* Lista de eventos */
  .eventos-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: relative;
  }
  
  /* Cada evento */
  .evento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 45px;
    text-align: center;
    padding: 20px;
  }
  
  .evento-numero {
    width: 30px;
    height: 30px;
    background-color: var(--evento-color, #ffffff);
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 8px;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .evento-numero:hover {
    transform: scale(1.2);
    background-color: #ffffff;
    color: var(--evento-color, #ffffff);
  }
  
  .evento-titulo {
    font-size: 9px;
    font-weight: normal;
    color: white;
  }
  </style>