<template>
  <div class="center-content">
    <img src="/img/logo_gob.2d63ceed.png" alt="Logo" class="logo-superior-derecha" />
    <img src="@/assets/logo.png" alt="Logo" class="logo-superior" />
    <h1 class="text-center text-white titulo">SISTEMA DE INTELIGENCIA Y ANÁLISIS GUBERNAMENTAL</h1>
    <h4 class="modal-title text-white">Bienvenido</h4>
    <p class="text-justify let">Inicia sesión</p>

    <!-- Mensaje de error -->
    <p v-if="mensajeError" class="text-danger text-center">{{ mensajeError }}</p>

    <form autocomplete="off">
      <input autocomplete="false" type="hidden" />
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="usuario">USUARIO</label>
          <input
            id="usuario"
            type="text"
            placeholder="Ingrese el usuario"
            maxlength="80"
            autocomplete="off"
            v-model.trim="$v.usuario.$model"
            :class="estatus($v.usuario)"
            class="form-control"
          />
          <div class="errores" v-if="!$v.usuario.required">Requerido</div>
        </div>

        <div class="form-group col-md-12">
          <label for="contrasena">CONTRASEÑA</label>
          <input
            id="contrasena"
            type="password"
            placeholder="Ingrese su contraseña"
            maxlength="80"
            autocomplete="off"
            v-model.trim="$v.contrasena.$model"
            :class="estatus($v.contrasena)"
            class="form-control"
          />
          <div class="errores" v-if="!$v.contrasena.required">Requerido</div>
        </div>
      </div>
    </form>

    <div class="col-md-12 text-center mt-2">
      <b-button 
        class="ingresar-btn"
      @click="iniciarSesion"
      >
        INGRESAR
      </b-button>
    </div>

    <h6 class="modal-title text-white mt-4">T L A X C A L A</h6>

    <snackbar
      baseSize="5rem"
      ref="snackbar"
      :holdTime="5000"
      position="top-center"
    />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Acceso",

  data() {
    return {
      usuario: "", // Se usa como 'identifier'
      contrasena: "",
      guardando: false,
      mensajeError: "", // Almacena mensaje de error
    };
  },

  validations: {
    usuario: { required },
    contrasena: { required },
  },

  methods: {
    // ✅ Método para iniciar sesión
    async iniciarSesion() {
      this.$v.$touch(); // Activa validaciones

      if (this.$v.$invalid) {
        this.mensajeError = "Todos los campos son obligatorios.";
        return;
      }

      this.mensajeError = ""; // Limpiar errores previos
      this.guardando = true;

      try {
        console.log("📡 Intentando iniciar sesión...");
        
        const resp = await this.$store.dispatch("iniciarSesionGestor", {
          identifier: this.usuario,
          password: this.contrasena,
        });

        // ✅ Verificar si la respuesta incluye un token
        if (resp.authorisation && resp.authorisation.token) {
          console.log("✅ Sesión iniciada correctamente. Redirigiendo a /home...");
          this.$router.push("/home");
        } else {
          this.mensajeError = resp.mensaje || "Error al iniciar sesión. Intente nuevamente.";
        }
      } catch (error) {
        console.error("❌ Error al iniciar sesión:", error);

        // ✅ Manejo de errores basado en la respuesta
        if (error.response) {
          if (error.response.status === 401) {
            this.mensajeError = "Credenciales incorrectas. Verifique su usuario y contraseña.";
          } else if (error.response.status === 500) {
            this.mensajeError = "Error del servidor. Inténtelo más tarde.";
          } else {
            this.mensajeError = "Error inesperado. Contacte con soporte.";
          }
        } else if (error.message === "Network Error") {
          this.mensajeError = "Error de red. Verifique su conexión.";
        } else {
          this.mensajeError = "Error desconocido. Intente nuevamente.";
        }
      } finally {
        this.guardando = false; // 🔄 Restaurar estado de carga
      }
    },

    // ✅ Método para determinar el estado de validación
    estatus(validation) {
      return validation.$dirty && !validation.$error ? "dirty" : "error";
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center-content {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  margin-top: 40px;
  flex-direction: column; /* Asegura que los elementos se apilen verticalmente */
  text-align: center; /* Opcional: centra el texto dentro del formulario */
}
.let{
  color:whitesmoke
}

/* Botón con el mismo ancho que los inputs */
.ingresar-btn {
  background-color: #00C4D8; /* Color turquesa */
  color: white; /* Texto en blanco */
  border: 2px solid white; /* Borde blanco */
  border-radius: 10px; /* Bordes redondeados */
  font-size: 1rem; /* Tamaño de la fuente */
  padding: 5px; /* Ajuste del relleno para mantener el ancho similar */
  max-width: 250px; /* Mismo ancho que los inputs */
  width: 110%; /* Para que el botón ocupe el 100% de su contenedor */
  cursor: pointer;
  text-transform: uppercase; /* Texto en mayúsculas */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Transición suave */
  display: block; /* Asegura que se mantenga como bloque */
  margin: 0 auto; /* Centrar horizontalmente */
}
.mt-4{
  font-weight: bold;
}
.titulo{
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 50px;
}

/* Hover: Cambia el color de fondo y el borde */
.ingresar-btn:hover {
  background-color: #006aa8; /* Color más oscuro en hover */
  border-color: #009ba8; /* Cambia el borde al hacer hover */
}
/* Agrupa label e input dentro del mismo bloque */
/* Agrupa label e input dentro del mismo bloque con fondo blanco */
.form-group {
  display: flex;
  flex-direction: initial; /* Etiqueta encima del input */
  align-items: center; /* Alinea a la izquierda */
  width: 100%; 
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco */
  border-radius: 10px; /* Bordes redondeados */
  padding: 10px 15px; /* Añade un padding interno */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.8); /* Sombra suave */
  max-width: 700px; /* Ancho máximo igual al del botón */
  box-sizing: border-box; /* Para incluir el padding en el ancho */
}

/* Ajustar el estilo de la etiqueta */
.form-group label {
  color: black; /* Texto en negro */
  font-weight: bold;
  margin-left: 10px;
  margin-right: -5px;
  margin-bottom: 0px; /* Separación entre el label y el input */
  text-align: left; /* Alinea el texto de las etiquetas a la izquierda */
  width: 100%; /* Ocupa el 100% del ancho del contenedor */
}

/* El input ocupa el 100% del espacio disponible */
.form-group input {
  background-color: rgba(255, 255, 255, 0); /* Fondo transparente (hereda del form-group) */
  border: none;
  padding: 5px 45px; /* Espaciado interno */
  font-size: 14px;
  width: 130%; /* Asegura que ocupe el 100% del ancho disponible */
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
  outline: none; /* Quita el borde de enfoque predeterminado */
}
.logo-superior-derecha {
  position: absolute;
  top: 15px; /* Ajusta la distancia desde la parte superior */
  right: 15px; /* Ajusta la distancia desde el lado derecho */
  width: 200px; /* Ajusta el tamaño del logo según sea necesario */
  height: auto; /* Mantiene las proporciones del logo */
  z-index: 10; /* Asegura que el logo esté encima del contenido */
}

@media only screen and (max-width: 768px) {
  .logo-superior-derecha {
    width: 130px; /* Ajusta el tamaño del logo para móviles */
    top: 10px; /* Puedes ajustar la distancia desde la parte superior */
    right: 10px; /* Ajusta la distancia desde el lado derecho */
  }
}

@media only screen and (max-width: 480px) {
  .logo-superior-derecha {
    width: 120px; /* Tamaño del logo para pantallas muy pequeñas */
    top: 8px; /* Ajusta la distancia desde la parte superior */
    right: 8px; /* Ajusta la distancia desde el lado derecho */
  }
}
.logo-superior{
  width: 200px; /* Tamaño del logo para pantallas muy pequeñas */
  top: 8px; /* Ajusta la distancia desde la parte superior */
  right: 8px;
}
</style>
