import Vue from "vue";
import Vuex from "vuex";
import apiGestor from "@/api/apiGestor";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gestorToken: localStorage.getItem("gestor_token") || null, // Restaurar token desde localStorage
    usuario: JSON.parse(localStorage.getItem("usuario")) || null, // Restaurar usuario desde localStorage
    isLoading: false, 
  },
  mutations: {
    SHOW_LOADER(state) {
      console.log("SHOW_LOADER called");
      state.isLoading = true; // Muestra el loader
    },
    HIDE_LOADER(state) {
      console.log("HIDE_LOADER called");
      state.isLoading = false; // Oculta el loader
    },
    // Guardar token en el estado y en localStorage
    setGestorToken(state, token) {
      console.log("Guardando token en Vuex:", token);
      state.gestorToken = token;
      localStorage.setItem("gestor_token", token);
    },
    // Guardar información del usuario en el estado y en localStorage
    setUsuario(state, usuario) {
      console.log("Guardando usuario en Vuex:", usuario);
      state.usuario = usuario;
      localStorage.setItem("usuario", JSON.stringify(usuario));
    },
    // Limpiar token y usuario en el estado y en localStorage
    clearGestorToken(state) {
      console.log("Limpiando token y usuario...");
      state.gestorToken = null;
      state.usuario = null;
      localStorage.removeItem("gestor_token");
      localStorage.removeItem("usuario");
    },
    // Mostrar loader

  },
  actions: {
    showLoader({ commit }) {
      console.log("Mostrando loader...");
      commit("SHOW_LOADER");
    },
    hideLoader({ commit }) {
      console.log("Ocultando loader...");
      commit("HIDE_LOADER");
    },
    // Acción para iniciar sesión
    async iniciarSesionGestor({ commit }, { identifier, password }) {
      try {
        console.log("🔐 Iniciando sesión...");
        const response = await apiGestor.post("/login", { identifier, password });

        const token = response.data.authorisation.token;
        const usuario = response.data.user;
        commit("setGestorToken", token);
        commit("setUsuario", usuario);
        return response.data;
      } catch (error) {
        console.error("❌ Error al iniciar sesión:", error);
        throw error;
      }
    },

    

    // Acción para obtener la lista de stakeholders
    async obtenerStakeholders({ state }) {
      if (!state.gestorToken) {
        throw new Error("No se ha iniciado sesión en la API Gestor.");
      }

      try {
        console.log("Obteniendo stakeholders...");
        const response = await apiGestor.get("/stakeholders", {
          headers: { Authorization: `Bearer ${state.gestorToken}` },
        });
        console.log("Stakeholders obtenidos:", response.data);
        return response.data;
      } catch (error) {
        console.error("Error al obtener los stakeholders:", error.response || error);
        throw error;
      }
    },

    // Acción para obtener detalles de un stakeholder específico
    async obtenerDetalleStakeholder({ state }, id) {
      if (!state.gestorToken) {
        throw new Error("No se ha iniciado sesión en la API Gestor.");
      }

      try {
        console.log("Obteniendo detalles del stakeholder con ID:", id);
        const response = await apiGestor.get(`/stakeholders/${id}`, {
          headers: { Authorization: `Bearer ${state.gestorToken}` },
        });
        console.log("Detalle del stakeholder obtenido:", response.data);
        return response.data;
      } catch (error) {
        console.error("Error al obtener los detalles del stakeholder:", error.response || error);
        throw error;
      }
    },

    // Restaurar sesión desde localStorage
    restaurarSesion({ commit }) {
      const token = localStorage.getItem("gestor_token");
      const usuario = localStorage.getItem("usuario");

      if (token) {
        console.log("Sesión restaurada con token:", token);
        commit("setGestorToken", token);
      } else {
        console.warn("No se encontró un token en localStorage.");
      }

      if (usuario) {
        commit("setUsuario", JSON.parse(usuario));
      }
    },
    terminarSesion({ commit }) {
      console.log("🚪 Cerrando sesión...");
      commit("clearGestorToken"); // Borra el token de Vuex
      localStorage.removeItem("gestor_token"); // Borra el token del almacenamiento local
    }
  },
  getters: {
    // ✅ Verifica si hay un token válido
    isGestorTokenValid(state) {
      return !!state.gestorToken;
    },

    // ✅ Verifica si hay una sesión iniciada
    sesionIniciada(state) {
      return !!state.gestorToken; // Retorna `true` si hay un token en Vuex
    },

    // ✅ Devuelve el usuario autenticado
    usuario(state) {
      return state.usuario || null;
    },

    // ✅ Verifica si el usuario tiene un permiso específico
    tienePermiso: (state) => (permisoRequerido) => {
      if (!state.usuario || !state.usuario.permisos) {
        return false; // Si el usuario no tiene permisos, retorna `false`
      }

      return state.usuario.permisos.includes(permisoRequerido);
    },
    // Estado del loader
    isLoading(state) {
      return state.isLoading;
    },
  }
});
