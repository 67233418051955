<template>
    <div class="riesgo-distritos-chart">
        <div class="header">
          <h3>Gráfica de Riesgos por Distrito Local</h3>
        </div>
        <v-chart :option="chartOptions" class="chart-container" />
        <div class="selector-periodo">
          <button
            v-for="(periodo, index) in periodos"
            :key="index"
            :class="{ active: periodo === periodoSeleccionado }"
            @click="cambiarPeriodo(periodo)">
            {{ periodo }}
          </button>
        </div>
      </div>
      
  </template>
  
  <script>
  import * as echarts from "echarts";
  
  export default {
    name: "RiesgoDistritosChart",
    data() {
      return {
        periodoSeleccionado: "Mes",
        periodos: ["Día", "Semana", "Mes"],
        datos: {
          Día: [
            { distrito: "Distrito I", maximo: 10, alto: 20, moderado: 30, menor: 40 },
            { distrito: "Distrito II", maximo: 5, alto: 10, moderado: 20, menor: 30 },
            // Más datos...
          ],
          Semana: [
            { distrito: "Distrito I", maximo: 50, alto: 40, moderado: 30, menor: 20 },
            { distrito: "Distrito II", maximo: 30, alto: 30, moderado: 20, menor: 10 },
            // Más datos...
          ],
          Mes: [
            { distrito: "Distrito I", maximo: 100, alto: 80, moderado: 60, menor: 40 },
            { distrito: "Distrito II", maximo: 80, alto: 60, moderado: 50, menor: 30 },
            { distrito: "Distrito III", maximo: 70, alto: 50, moderado: 40, menor: 20 },
            { distrito: "Distrito IV", maximo: 60, alto: 40, moderado: 30, menor: 10 },
            { distrito: "Distrito V", maximo: 50, alto: 30, moderado: 20, menor: 10 },
            { distrito: "Distrito VI", maximo: 40, alto: 20, moderado: 10, menor: 5 },
            { distrito: "Distrito VII", maximo: 30, alto: 10, moderado: 5, menor: 2 },
            { distrito: "Distrito VIII", maximo: 20, alto: 5, moderado: 2, menor: 1 },
          ],
        },
      };
    },
    computed: {
      chartOptions() {
        const data = this.datos[this.periodoSeleccionado];
        return {
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "shadow" },
          },
          legend: {
            data: ["Máximo", "Alto", "Moderado", "Menor"],
            textStyle: { color: "#ffffff" },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: data.map((item) => item.distrito),
            axisLabel: { color: "#ffffff" },
          },
          yAxis: {
            type: "value",
            axisLabel: { color: "#ffffff" },
          },
          series: [
            {
              name: "Máximo",
              type: "bar",
              stack: "total",
              data: data.map((item) => item.maximo),
              itemStyle: { color: "#FF0000" },
            },
            {
              name: "Alto",
              type: "bar",
              stack: "total",
              data: data.map((item) => item.alto),
              itemStyle: { color: "#FFA500" },
            },
            {
              name: "Moderado",
              type: "bar",
              stack: "total",
              data: data.map((item) => item.moderado),
              itemStyle: { color: "#FFFF00" },
            },
            {
              name: "Menor",
              type: "bar",
              stack: "total",
              data: data.map((item) => item.menor),
              itemStyle: { color: "#00FF00" },
            },
          ],
        };
      },
    },
    methods: {
      cambiarPeriodo(periodo) {
        this.periodoSeleccionado = periodo;
      },
    },
  };
  </script>
  
  <style scoped>
  .riesgo-distritos-chart {
    display: flex;
    flex-direction: column;
    background-color: #2e3b4e;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100px; /* Ajusta el ancho del contenedor */
    height: 260px; /* Ajusta la altura del contenedor */
    margin-right: 100px;
    margin-left: -30px;
    
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: white;
  }
  .header h3{
    font-size: 15px;
    font-weight: bold;
  }
  
  .selector-periodo {
    display: flex;
    justify-content: center; /* Centra los botones */
    gap: 10px; /* Espaciado entre los botones */
    margin-top: 10px; /* Añade espacio superior */
  }
  
  .selector-periodo button {
    background-color: #1e4059;
    flex-direction: column;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 3px 7px;
    font-size: 12px; /* Tamaño de texto más pequeño */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .selector-periodo button:hover {
    background-color: #33b5e5;
  }
  
  .selector-periodo button.active {
    background-color: #2ecc71;
    font-weight: bold;
  }
  
  .chart-container {
    width: 100%;
    height: 200px; /* Reduce la altura del gráfico */
  }
  </style>
  
  