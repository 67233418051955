<template>
    <div class="red-vinculo-container">
      <h3>Visualización de la Red de Vínculos</h3>
      <div class="detalle-red">
        <p><strong>CASO:</strong> {{ caso }}</p>
        <p><strong>MUNICIPIO:</strong> {{ municipio }}</p>
        <p><strong>AGENTE:</strong> {{ agente }}</p>
        <p><strong>VINCULADOS:</strong> {{ vinculados.length }} entidades vinculadas</p>
      </div>
      <div id="red-vinculo"></div>
    </div>
  </template>
  
  <script>
  import cytoscape from "cytoscape"; // Asegúrate de instalar Cytoscape: `npm install cytoscape`
import { image } from "d3";
  
  export default {
    name: "RedVinculo",
    data() {
      return {
        caso: "Manifestación de Pobladores ante la Construcción del Libramiento a Calpulalpan",
        municipio: "Calpulalpan",
        agente: "Antonio Pérez Valencia",
        vinculados: [
          // Datos simulados de nodos y relaciones
          { id: "1", label: "Nodo Principal", type: "central" },
          { id: "2", label: "Entidad 1", type: "linked" },
          { id: "3", label: "Entidad 2", type: "linked" },
          { id: "4", label: "Entidad 3", type: "linked" },
          { id: "5", label: "Entidad 4", type: "linked" },
        ],
        relaciones: [
          { source: "1", target: "2" },
          { source: "1", target: "3" },
          { source: "2", target: "4" },
          { source: "3", target: "5" },
        ],
      };
    },
    mounted() {
      // Configurar y renderizar la red de vínculos
      const cy = cytoscape({
        container: document.getElementById("red-vinculo"),
        elements: [
          // Agregar nodos
          ...this.vinculados.map((vinculo) => ({
            data: { id: vinculo.id, label: vinculo.label, type: vinculo.type },
          })),
          // Agregar relaciones (aristas)
          ...this.relaciones.map((relacion) => ({
            data: { source: relacion.source, target: relacion.target },
          })),
        ],
        style: [
          {
            selector: "node",
            style: {
              "background-color": (ele) =>
                ele.data("type") === "central" ? "#FF5733" : "#33B5FF",
              label: "data(label)",
              "text-valign": "center",
              "text-halign": "center",
              "font-size": "10px",
              color: "#fff",
              "text-outline-width": 2,
              "text-outline-color": "#000",
            },
          },
          {
            selector: "edge",
            style: {
              width: 2,
              "line-color": "#ccc",
              "target-arrow-color": "#ccc",
              "target-arrow-shape": "triangle",
            },
          },
        ],
        layout: {
          name: "cose", // Layout orgánico
          idealEdgeLength: 100,
          nodeRepulsion: 4000,
          fit: true,
        },
      });
  
      // Ajustar tamaño al cargar
      cy.resize();
    },
  };
  </script>
  
  <style scoped>
  .red-vinculo-container {
    width: 150px;
    height: 500px;
    background-color: #1e2a38;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
  }
  .red-vinculo-container h3{
    font-size: 15px;
    font-weight: bold;
  }
  .red-vinculo-container p{
    font-size: 10px;
  }
  
  .detalle-red {
    margin-bottom: 10px;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  #red-vinculo {
    width: 300px;
    height: 250px;
    background-color: #2e3b4e;
    border-radius: 8px;
  }
  </style>
  