import { render, staticRenderFns } from "./ReportesInvestigacionChart.vue?vue&type=template&id=2e06c174&scoped=true"
import script from "./ReportesInvestigacionChart.vue?vue&type=script&lang=js"
export * from "./ReportesInvestigacionChart.vue?vue&type=script&lang=js"
import style0 from "./ReportesInvestigacionChart.vue?vue&type=style&index=0&id=2e06c174&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e06c174",
  null
  
)

export default component.exports