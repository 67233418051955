<template>
  <div id="app">
    <!-- Loader controlado por estado local -->
    <div class="loader-container" :class="{ 'final-spin': isFinalSpin }" v-if="isLoading">
      <div class="loader">
        <div class="circle-outer"></div> <!-- Círculo exterior con pulsación -->
        <div class="circle-inner"></div>  <!-- Círculo interior con rotación -->
      </div>
    </div>

    <!-- Contenido de la App -->
    <router-view v-show="!isLoading" />
  </div>
</template>

<script>
import * as utils from '@/utils'
import * as api from '@/api'

export default {
  name: "App",
  data() {
    return {
      avisomsg: "",
      titulomsg: "",
      sesionsr: api.TIEMPO_SESION,
      controlTiempo: null,
      isLoading: false, // Control del estado del loader
      isFinalSpin: false, // Nuevo estado para activar el giro final
      startTime: 0, // Guarda el tiempo de inicio de la carga
    };
  },
  methods: {
    showLoader() {
      console.log("Mostrando loader...");
      this.isLoading = true;
      this.isFinalSpin = false; // Reiniciar estado de animación final
      this.startTime = Date.now(); // Guardar el tiempo de inicio
    },
    hideLoader() {
      const loadTime = Date.now() - this.startTime; // Calcular tiempo de carga real
      const minTime = 1000; // Tiempo mínimo para el giro (1s)
      const extraDelay = Math.max(minTime - loadTime, 0); // Ajuste dinámico

      console.log(`Tiempo de carga: ${loadTime}ms, Ajuste de espera: ${extraDelay}ms`);

      // Activar el giro 3D antes de ocultar
      this.isFinalSpin = true;
      
      setTimeout(() => {
        this.isLoading = false;
        this.isFinalSpin = false;
      }, minTime + extraDelay); // Asegurar que siempre haya un giro final
    },
    error(mensaje) {
      this.$refs.mensaje.error(mensaje);
    },
    mensaje(mensaje) {
      this.$refs.mensaje.info(mensaje);
    },
    aviso(mensaje) {
      this.titulomsg = "Aviso";
      this.avisomsg = mensaje;
      this.$refs.aviso.show();
    },
    alerta(mensaje) {
      this.titulomsg = "Error";
      this.avisomsg = mensaje;
      this.$refs.aviso.show();
    },
    avisoTiempoSesion() {
      this.sesionsr = api.TIEMPO_SESION;
      this.$refs.sesion.show();
    },
    renovarSesion() {
      this.$refs.sesion.hide();
      this.$store.dispatch("renovarSesion");
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    usuario() {
      return this.$store.getters.usuario;
    },
    nombreCompleto() {
      if (this.sesionIniciada) {
        let usuario = this.$store.getters.usuario;
        if (usuario != null) {
          return usuario.nombre;
        }
      }
      return "Invitado";
    },
    sesionIniciada() {
      return this.$store.getters.sesionIniciada();
    },
    esAdministrador() {
      return this.$store.getters.esAdmin;
    },
  },
  mounted() {
    // Vincular el estado del loader con el router
    this.$router.beforeEach((to, from, next) => {
      this.showLoader(); // Mostrar loader antes de la navegación
      next();
    });

    this.$router.afterEach(() => {
      setTimeout(() => this.hideLoader(), 500); // Ocultar loader con un retraso
    });
  },
};
</script>


<style>
/* Contenedor del loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(30, 42, 56, 0.6); /* Fondo oscuro con transparencia */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.5s ease, visibility 0.5s ease; /* Transición al ocultar */
}

/* Loader principal */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  position: relative;
}

/* Círculo exterior (Pulsación) */
.circle-outer {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(51, 181, 229, 0.5); /* Borde translúcido */
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

/* Línea azul interior (Rotación) */
.circle-inner {
  width: 64px;
  height: 64px;
  border: 4px solid transparent;
  border-top: 4px solid #33b5e5; /* Línea azul */
  border-radius: 50%;
  position: absolute;
  animation: spin 1s linear infinite;
  transform-origin: center center;
}

/* Animación de rotación normal */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Animación de pulsación */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* 🚀 Animación final: Giro 3D antes de desaparecer */
.loader-container.final-spin .circle-inner {
  animation: spin3D 1s ease-in-out;
}

/* 🎯 Definimos el giro en el eje X */
@keyframes spin3D {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}


#app {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff; /* Cambia el texto a blanco */
  font-size: 0.9em;
  background: none; /* Asegura que no haya fondo adicional */
}

#app::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Combina el gradiente con la imagen de fondo */
  background-image: 
    linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), /* Aumenta la opacidad del negro */
    url('./assets/inicio.fw.png'); /* Imagen de fondo */

  background-size: cover; /* Imagen cubre toda la pantalla */
  background-position: center center; /* Centra la imagen */
  background-repeat: no-repeat; /* No repetir la imagen */
  background-attachment: fixed; /* El fondo permanece fijo al hacer scroll */
  
  /* Asegura que el fondo cubra toda la pantalla */
  min-height: 100vh; /* Altura mínima del viewport */
  
  z-index: -1; /* Asegura que la imagen esté detrás del contenido */
}

/* Soporte para WebP */
@supports (background-image: url('data:image/webp;base64,UklGRhIAAABXRUJQVlA4TAYAAAAvAAAAAAfQ//73v/+BiOh/AAA=')) {
  #app::before {
    background-image: 
      linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
      url('./assets/inicio.fw.webp'); /* Imagen WebP */
      background-size: cover; /* Imagen cubre toda la pantalla */
  background-position: center center; /* Centra la imagen */
  background-repeat: no-repeat; /* No repetir la imagen */
  background-attachment: fixed; /* El fondo permanece fijo al hacer scroll */
  position:fixed;
  
  /* Asegura que el fondo cubra toda la pantalla */
  min-height: 100vh; /* Altura mínima del viewport */
  
  z-index: -1
  }
}

/* Media query para dispositivos móviles */
@media only screen and (max-width: 768px) {
  #app::before {
    background-image: 
      linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), /* Aumenta la opacidad del negro */
      url('./assets/bk-movil.webp'); /* Imagen de fondo para móviles */

    background-size: cover; /* Imagen cubre toda la pantalla */
    background-position: center center; /* Centra la imagen */
    background-repeat: no-repeat; /* No repetir la imagen */
    background-attachment: scroll; /* El fondo se desplaza con el contenido en móviles */
  }

  /* Soporte para WebP en móviles */
  @supports (background-image: url('data:image/webp;base64,UklGRhIAAABXRUJQVlA4TAYAAAAvAAAAAAfQ//73v/+BiOh/AAA=')) {
    #app::before {
      background-image: 
        linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
        url('./assets/bk-movil.webp'); /* Imagen WebP para móviles */
    }
  }
}
.tree-item,
.collapsible-section {
  background-color: rgba(0, 0, 0, 0.2); /* Fondo negro translúcido */
  color: #fff; /* Texto en blanco */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borde blanco suave */
  border-radius: 5px;
  margin-bottom: 10px; /* Espacio entre elementos */
}
.tree-item h4,
.collapsible-section h4 {
  color: #fff; /* Títulos en blanco */
}


#nav {
  padding: 30px;
}

#nav a,
a {
  font-weight: bold;
  color: #8c2054;
}

#nav a.router-link-exact-active {
  color: #8c2054;
}

.container {
  background-color: white;
  min-height: 100%;
}
h4 {
  font-size: 1.4rem;
  color: #992f5f;
  line-height: 2rem;
}
h5 {
  font-size: 1.3rem;
  color: #992f5f;
  line-height: 1.8rem;
}
hr {
  border: none; /* Elimina bordes por defecto */
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Línea blanca tenue */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.grupo {
  padding: 10px;
  border-radius: 5px;
  margin: 1rem 0;
  background-color: #f8f9faab;
  border: solid #f8f9fa;
  border-width: 0.2rem 0;
}
.errores {
  font-size: 0.75rem;
  color: red;
}
.dirty,
.dirty:focus {
  border-color: #5a5;
}

.error,
.error:focus {
  border-color: red;
}


body.theme-light {
  --layer-base: 0;
  --layer-overlay: 1;
  --layer-modal: 2;
  --layer-top: 3;

  --color-accent-background: #a03bc3;
  --color-accent-foreground: #fff;

  --color-danger-background: #e74c3c;
  --color-danger-foreground: #fff;

  --color-warning-background: #f1c40f;
  --color-warning-foreground: #fff;

  --color-success-background: #2ecc71;
  --color-success-foreground: #fff;

  --color-info-background: #3498db;
  --color-info-foreground: #fff;

  --color-text: #333;

  --color-toolbar-background: #fff;
  --color-toolbar-foreground: #fff;
  --color-toolbar-border: var(--color-accent-background);
  --color-toolbar-text: var(--color-text);
  --color-toolbar-text-active: #fff;
  --color-toolbar-foreground-active: var(--color-accent-background);

  --color-main-background: #fff;
  --color-main-foreground: #e0e0e0;

  --color-statusbar-background: white;
  --color-statusbar-foreground: #ecf0f1;
  --color-statusbar-hover: #bdc3c7;
  --color-statusbar-active: #95a5a6;
  --color-statusbar-separator: #e5e5e5;
  --color-statusbar-text: var(--color-text);

  --color-input-background: #fff;
  --color-input-border: #e0e0e0;
  --color-input-text: var(--color-text);
  --color-input-background-focus: #fff;
  --color-input-border-focus: var(--color-accent-background);

  --color-button-background: #333;
  --color-button-foreground: #fff;
  --color-button-background-secondary: #fff;
  --color-button-foreground-secondary: #333;

  --color-dialog-background: #fff;
  --color-dialog-foreground: #ededed;
  --color-dialog-border: #e0e0e0;
  --color-dialog-text: var(--color-text);
  --color-dialog-foreground-active: var(--color-accent-background);
  --color-dialog-text-active: #fff;

  --color-contextmenu-background: #fff;
  --color-contextmenu-foreground: #333;
  --color-contextmenu-border: #e0e0e0;
  --color-contextmenu-text: var(--color-text);
  --color-contextmenu-foreground-active: var(--color-accent-background);
  --color-contextmenu-text-active: #fff;

  --color-tree-node-background: #282c34;
  --color-tree-node-text: var(--color-text);
  --color-tree-node-background-active: rgba(128, 128, 128, 0.3);

  --color-tree-line-background: #000;

  --color-notebook-note-background: #fff;
  --color-notebook-note-border: #ccc;
  --color-notebook-note-text: var(--color-text);
  --color-notebook-note-background-active: #dcdcdc;
  --color-notebook-note-border-active: #ccc;

  --color-notebook-group-background: transparent;
  --color-notebook-group-border: #ddd;
  --color-notebook-group-handle: #ccc;
  --color-notebook-group-background-active: rgba(128, 128, 128, 0.15);
  --color-notebook-group-border-active: rgba(128, 128, 128, 0.15);
  --color-notebook-group-handle-active: #aaa;

  --color-notebook-shadow-border: #aaa;
}

body.theme-dark {
  --color-accent-background: #5c6370;
  --color-accent-foreground: #fff;

  --color-danger-background: #9b2915;
  --color-danger-foreground: #fff;

  --color-warning-background: #c99b0b;
  --color-warning-foreground: #fff;

  --color-success-background: #1e8649;
  --color-success-foreground: #fff;

  --color-info-background: #1e6d99;
  --color-info-foreground: #fff;

  --color-text: #ddd;

  --color-toolbar-background: #262626;
  --color-toolbar-foreground: #262626;
  --color-toolbar-border: var(--color-accent-background);
  --color-toolbar-text: var(--color-text);
  --color-toolbar-text-active: #fff;
  --color-toolbar-foreground-active: var(--color-accent-background);

  --color-main-background: #262626;
  --color-main-foreground: #4d4d4d;

  --color-statusbar-background: #262626;
  --color-statusbar-foreground: #4d4d4d;
  --color-statusbar-hover: #353535;
  --color-statusbar-active: #4d4d4d;
  --color-statusbar-separator: #353535;
  --color-statusbar-text: var(--color-text);

  --color-input-background: #4d4d4d;
  --color-input-border: #666;
  --color-input-text: var(--color-text);
  --color-input-background-focus: #666;
  --color-input-border-focus: var(--color-accent-background);

  --color-button-background: #333;
  --color-button-foreground: #fff;
  --color-button-background-secondary: #262626;
  --color-button-foreground-secondary: #ddd;

  --color-dialog-background: #262626;
  --color-dialog-foreground: #4d4d4d;
  --color-dialog-border: #666;
  --color-dialog-text: var(--color-text);
  --color-dialog-foreground-active: var(--color-accent-background);
  --color-dialog-text-active: #fff;

  --color-contextmenu-background: #1f1f1f;
  --color-contextmenu-foreground: #666;
  --color-contextmenu-border: #666;
  --color-contextmenu-text: var(--color-text);
  --color-contextmenu-foreground-active: var(--color-accent-background);
  --color-contextmenu-text-active: #fff;

  --color-tree-node-background: #1f1f1f;
  --color-tree-node-text: var(--color-text);
  --color-tree-node-background-active: rgba(128, 128, 128, 0.3);

  --color-tree-line-background: #666;

  --color-notebook-note-background: #262626;
  --color-notebook-note-border: #666;
  --color-notebook-note-text: var(--color-text);
  --color-notebook-note-background-active: #4d4d4d;
  --color-notebook-note-border-active: #666;

  --color-notebook-group-background: transparent;
  --color-notebook-group-border: #666;
  --color-notebook-group-handle: #666;
  --color-notebook-group-background-active: rgba(128, 128, 128, 0.15);
  --color-notebook-group-border-active: rgba(128, 128, 128, 0.15);
  --color-notebook-group-handle-active: #999;

  --color-notebook-shadow-border: #666;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  width: 100%;
  height: 100%;

  font-family: 'Roboto', sans-serif;
}

</style>
