<template>
    <div class="desglose-semaforo-container">
      <div class="header">
        <h3>TOP PROBLEMAS LATENTES EN TLAXCALA</h3>
        <div class="controls">
            <button @click="cambiarMes('anterior')">◄</button>
            <span>{{ mesSeleccionado }}</span>
            <button @click="cambiarMes('siguiente')">►</button>
          </div>
          <div class="controls">
            <button @click="cambiarNivel('anterior')">◄</button>
            <span>{{ nivelSeleccionado }}</span>
            <button @click="cambiarNivel('siguiente')">►</button>
          </div>          
      </div>
      <div class="tabla-container">
        <table class="tabla-semaforo">
          <thead>
            <tr>
              <th>Municipio</th>
              <th>Agente Asignado</th>
              <th>Reporte PDF</th>
              <th>Red de Vínculos</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(registro, index) in registrosFiltrados"
              :key="index"
              :class="{ highlight: registro.nivel === nivelSeleccionado }"
            >
              <td>{{ registro.municipio }}</td>
              <td>{{ registro.agente }}</td>
              <td><img src="@/assets/icons/pdf.png" alt="PDF" class="pdf-icon" /></td>
              <td><a href="#" class="vinculo-link">Ver Visualización</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "DesgloseSemaforoChart",
    data() {
      return {
        mesSeleccionado: "Diciembre",
        nivelSeleccionado: "Menor",
        meses: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        niveles: ["Menor", "Moderado", "Alto", "Máximo"],
        registros: [
          { municipio: "Amaxac de Guerrero", agente: "Guillermo Israel López", nivel: "Menor" },
          { municipio: "España", agente: "Antonio Pérez", nivel: "Menor" },
          { municipio: "Tlaxcala", agente: "Antonio Pérez", nivel: "Menor" },
          { municipio: "Chiautempan", agente: "Antonio Pérez", nivel: "Menor" },
          { municipio: "Huamantla", agente: "Antonio Pérez", nivel: "Moderado" },
          { municipio: "Apizaco", agente: "Guillermo Israel López", nivel: "Alto" },
          { municipio: "Calpulalpan", agente: "Antonio Pérez", nivel: "Máximo" },
        ],
      };
    },
    computed: {
      registrosFiltrados() {
        return this.registros.filter(
          (registro) => registro.nivel === this.nivelSeleccionado
        );
      },
    },
    methods: {
      cambiarMes(direccion) {
        const index = this.meses.indexOf(this.mesSeleccionado);
        if (direccion === "anterior") {
          this.mesSeleccionado = this.meses[(index - 1 + this.meses.length) % this.meses.length];
        } else {
          this.mesSeleccionado = this.meses[(index + 1) % this.meses.length];
        }
      },
      cambiarNivel(direccion) {
        const index = this.niveles.indexOf(this.nivelSeleccionado);
        if (direccion === "anterior") {
          this.nivelSeleccionado = this.niveles[(index - 1 + this.niveles.length) % this.niveles.length];
        } else {
          this.nivelSeleccionado = this.niveles[(index + 1) % this.niveles.length];
        }
      },
    },
  };
  </script>
  <style scoped>
  .desglose-semaforo-container {
    width: 400px;
    max-width: 400px;
    height: 250px;
    background-color: #1e2a38;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    margin-left: -550px;
    transform: translate(-400px, -250px);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;
  }
  .header h3{
    font-size: 13px;
    font-weight: bold;
  }
  
  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px; /* Espacio entre los elementos */
  }
  
  .controls button {
    background-color: #1e4059;
    border: none;
    color: white;
    padding: 4px 6px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 8px;
    transition: background-color 0.3s;
  }
  
  .controls span {
    font-size: 10px;
    color: white;
    font-weight: bold;
    margin: 2px 0; /* Espaciado entre los botones */
  }
  
  
  .controls button:hover {
    background-color: #33b5e5;
  }
  
  .tabla-container {
    max-height: 175px;
    overflow-y: auto;
    margin-top: 10px;
  }
  
  .tabla-semaforo {
    width: 350px;
    height: 110px;
    border-collapse: collapse;
  }
  
  .tabla-semaforo th,
  .tabla-semaforo td {
    padding: 5px 8px;
    text-align: center;
    font-size: 0.8rem;
  }
  
  .tabla-semaforo th {
    background-color: #2e3b4e;
  }
  
  .tabla-semaforo td {
    background-color: #1e4059;
    border-bottom: 1px solid #2e3b4e;
    color: white;
  }
  
  .tabla-semaforo tr.highlight td {
    background-color: transparent;
    color: #fff;
  }
  
  .pdf-icon {
    width: 20px;
    height: 20px;
  }
  
  .vinculo-link {
    color: #33b5e5;
    text-decoration: underline;
    cursor: pointer;
  }
  </style>
  