import { render, staticRenderFns } from "./SemaforoProblemasChart.vue?vue&type=template&id=bf30a94a&scoped=true"
import script from "./SemaforoProblemasChart.vue?vue&type=script&lang=js"
export * from "./SemaforoProblemasChart.vue?vue&type=script&lang=js"
import style0 from "./SemaforoProblemasChart.vue?vue&type=style&index=0&id=bf30a94a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf30a94a",
  null
  
)

export default component.exports