<template>
    <div class="mapa-riesgo-container">
      <h3>Mapa de Riesgo Estatal</h3>
      <div id="mapa-riesgo"></div>
    </div>
  </template>
  
  <script>
  import L from "leaflet"; // Asegúrate de tener Leaflet instalado
  
  export default {
    name: "MapaRiesgoEstatal",
    mounted() {
      // Crear el mapa
      const map = L.map("mapa-riesgo").setView([19.316, -98.241], 8.4);
  
      // Añadir capa base
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(map);
  
      // Cargar y mostrar el geojson
      fetch("/data/tlaxcala.geojson")
        .then((response) => response.json())
        .then((geojsonData) => {
          // Añadir el geojson al mapa
          L.geoJSON(geojsonData, {
            style: {
              color: "#ffffff", // Bordes blancos
              weight: 2, // Grosor de los bordes
              fillColor: "#FF5733", // Color de relleno (puedes cambiarlo para resaltar)
              fillOpacity: 0.6, // Transparencia del relleno
            },
          }).addTo(map);
        })
        .catch((error) => {
          console.error("Error cargando el archivo geojson:", error);
        });
    },
  };
  </script>
  
  <style scoped>
  .mapa-riesgo-container {
    width: 100%;
    height: 100%;
    background-color: #1e2a38;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-left: -100px;
    height: 260px;
    width: 300px;
  }

  .mapa-riesgo-container h3{
    font-size: 15px;
    font-weight: bold;
  }
  
  #mapa-riesgo {
    width: 100%;
    height: 200px;
    border-radius: 8px;
  }
  </style>
