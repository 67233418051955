import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import Snackbar from 'vuejs-snackbar';
import axios from 'axios';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//import 'bootstrap/dist/css/bootstrap.css'
import '@/css/bootstrap-custom.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'leaflet/dist/leaflet.css'
import ECharts from "vue-echarts";
import "echarts"; // Importa ECharts
import "echarts-wordcloud";
Vue.component("v-chart", ECharts);



import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCropAlt, faTrash, faExclamationCircle, faFileUpload,
  faHome, faImage, faCamera, faVideo, faEdit, faFile, faPlusSquare,
  faFilePdf, faInfoCircle, faFileAlt, faChevronDown, faAngleRight,
  faArrowAltCircleLeft, faBriefcase, faTrashAlt, faUser, faFileExport, faFileImport,
  faPlus, faFolder, faFolderOpen, faList, faClipboard, faCheckSquare, faThList
} from '@fortawesome/free-solid-svg-icons'

import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { Icon } from 'leaflet';

//Icono en ubicación
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


library.add(faHome)
library.add(faBriefcase)
library.add(faCamera);
library.add(faImage);
library.add(faCropAlt);
library.add(faFileUpload);
library.add(faVideo);
library.add(faEdit);
library.add(faTrash);
library.add(faFilePdf);
library.add(faPlusSquare);
library.add(faFontAwesome)
library.add(faFile);
library.add(faInfoCircle);
library.add(faExclamationCircle);
library.add(faFileAlt);
library.add(faPlus)
library.add(faTrashAlt)
library.add(faChevronDown)
library.add(faAngleRight)
library.add(faArrowAltCircleLeft);
library.add(faFileExport)
library.add(faFileImport)
library.add(faUser)
library.add(faList)
library.add(faFolder)
library.add(faFolderOpen)
library.add(faClipboard);
library.add(faCheckSquare);
library.add(faThList);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('snackbar', Snackbar);



Vue.use(BootstrapVue, {
  theme: {
    "primary": "#FFCA28",
    "secondary": "#1976D2",
    "accent": "#82B1FF",
    "error": "#FF5252",
    "info": "#2196F3",
    "success": "#4CAF50",
    "warning": "#FB8C00"
  }
})
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
axios.defaults.withCredentials = true

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.app = app;
