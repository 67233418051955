<template>
  <div class="content">
    <div class="header-section">
      <div class="logo-section">
        <img src="@/assets/logo_tlah.png" class="logo" />
      </div>
      <div class="report-count-section">
        <p>CONCENTRADO REPORTES</p>
        <h4>Distritos y Municipios</h4>
        <p>Reportes totales: 185</p>
      </div>

      <div class="status-indicators-horizontal">
        <div class="status-row">
          <div class="indicator">
            <span class="status-label">Pendiente</span>
            <span class="badge red">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Proceso</span>
            <span class="badge yellow">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Concluido</span>
            <span class="badge green">01</span>
          </div>
        </div>
        <div class="priority-row">
          <div class="indicator">
            <span class="status-label">Baja</span>
            <span class="badge light-green">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Media</span>
            <span class="badge orange">01</span>
          </div>
          <div class="indicator">
            <span class="status-label">Alta</span>
            <span class="badge red">01</span>
          </div>
        </div>
      </div>
    </div>

    <div class="filters-section">
      <div>
        <p>
          ESTATUS DE LOS REPORTES
        </p>
      </div>
      <!-- Filtro de Municipio -->
      <div class="filter-card" @click="accionarFiltro('municipio')">
        <span class="filter-label">MUNICIPIO</span>
        <i class="fas fa-filter filter-icon"></i>
        <b-form-select
          ref="municipio"
          v-model="busqueda.municipio"
          :options="municipios"
          class="filter-input"
        ></b-form-select>
      </div>

      <!-- Filtro de Estatus -->
      <div class="filter-card" @click="accionarFiltro('estatus')">
        <span class="filter-label">ESTATUS</span>
        <i class="fas fa-filter filter-icon"></i>
        <b-form-select
          ref="estatus"
          v-model="busqueda.estatus"
          :options="estatus"
          class="filter-input"
        ></b-form-select>
      </div>

      <!-- Filtro de Gravedad -->
      <div class="filter-card" @click="accionarFiltro('gravedad')">
        <span class="filter-label">GRAVEDAD</span>
        <i class="fas fa-filter filter-icon"></i>
        <b-form-select
          ref="gravedad"
          v-model="busqueda.gravedad"
          :options="gravedades"
          class="filter-input"
        ></b-form-select>
      </div>

      <!-- Filtro de Fecha -->
      <div class="filter-card" @click="accionarFiltro('fecha')">
        <span class="filter-label">FECHA</span>
        <i class="fas fa-calendar-alt filter-icon"></i>
        <b-form-datepicker
          ref="fecha"
          v-model="busqueda.fecha"
          class="filter-input"
        ></b-form-datepicker>
      </div>

      <button class="btn-export">Exportar</button>
    </div>
    <div class="table-section">
      <!-- Encabezado -->
      <div class="table-header">
        <div class="table-header-item">ESTATUS</div>
        <div class="table-header-item">TOTALES</div>
        <div class="table-header-item">PRIORIDAD</div>
        <div class="table-header-item">FECHA</div>
        <div class="table-header-item">CREADO POR</div>
      </div>

      <!-- Filas -->
      <div class="table-row" style="background-color: #7a2121;">
        <div class="table-cell">REPORTES PENDIENTES (ASIGNADOS)</div>
        <div class="table-cell">4</div>
        <div class="table-cell">--</div>
        <div class="table-cell">--</div>
        <div class="table-cell">Tlatoani</div>
      </div>
      <div class="table-row" style="background-color: #9a8030;">
        <div class="table-cell">REPORTES EN PROCESO</div>
        <div class="table-cell">9</div>
        <div class="table-cell">--</div>
        <div class="table-cell">--</div>
        <div class="table-cell">Tlatoani</div>
      </div>
      <div class="table-row" style="background-color: #006837;">
        <div class="table-cell">REPORTES CONCLUIDOS</div>
        <div class="table-cell">29</div>
        <div class="table-cell">--</div>
        <div class="table-cell">--</div>
        <div class="table-cell">Tlatoani</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
import { required, url } from "vuelidate/lib/validators";

export default {
  name: "MisReportes",
  data() {
    return {
      listado: [],
      busqueda: {
        municipio: '',
        estatus: '',
        gravedad: '',
        fecha: moment().format('YYYY-MM-DD')
      },
      municipios: [
  'Acuamanala de Miguel Hidalgo',
  'Altzayanca',
  'Amaxac de Guerrero',
  'Apetatitlán de Antonio Carvajal',
  'Apizaco',
  'Atlangatepec',
  'Atltzayanca',
  'Benito Juárez',
  'Calpulalpan',
  'Chiautempan',
  'Contla de Juan Cuamatzi',
  'Cuapiaxtla',
  'Cuaxomulco',
  'El Carmen Tequexquitla',
  'Emiliano Zapata',
  'Españita',
  'Huamantla',
  'Hueyotlipan',
  'Ixtacuixtla de Mariano Matamoros',
  'Ixtenco',
  'La Magdalena Tlaltelulco',
  'Lázaro Cárdenas',
  'Mazatecochco de José María Morelos',
  'Muñoz de Domingo Arenas',
  'Nanacamilpa de Mariano Arista',
  'Natívitas',
  'Panotla',
  'Papalotla de Xicohténcatl',
  'San Damián Texóloc',
  'San Francisco Tetlanohcan',
  'San Jerónimo Zacualpan',
  'San José Teacalco',
  'San Juan Huactzinco',
  'San Lorenzo Axocomanitla',
  'San Lucas Tecopilco',
  'San Pablo del Monte',
  'Sanctórum de Lázaro Cárdenas',
  'Santa Ana Nopalucan',
  'Santa Apolonia Teacalco',
  'Santa Catarina Ayometla',
  'Santa Cruz Quilehtla',
  'Santa Cruz Tlaxcala',
  'Santa Isabel Xiloxoxtla',
  'Tenancingo',
  'Teolocholco',
  'Tepetitla de Lardizábal',
  'Tepeyanco',
  'Terrenate',
  'Tetla de la Solidaridad',
  'Tetlatlahuca',
  'Tlaxcala',
  'Tlaxco',
  'Tocatlán',
  'Totolac',
  'Tzompantepec',
  'Xaloztoc',
  'Xaltocan',
  'Xicohtzinco',
  'Yauhquemehcan',
  'Zacatelco',
  'Ziltlaltépec de Trinidad Sánchez Santos'
],
      estatus: ['Pendiente', 'En proceso', 'Concluido'],
      gravedades: ['Baja', 'Media', 'Alta']
    };
  },
  methods: {
    accionarFiltro(tipo) {
      // Simula un clic en el filtro correspondiente
      const filtro = this.$refs[tipo];
      if (filtro && filtro.$el) {
        const elemento = filtro.$el.querySelector("select, input");
        if (elemento) elemento.click();
      }
    },
    agregar() {
      console.log("Agregar nuevo reporte");
    },
    actualizaReportes() {
      api.buscarEntradas(this.busqueda).then(r => {
        if (r.data.respuesta) {
          this.listado = r.data.entradas;
        }
      });
    }
  },
  mounted() {
    this.actualizaReportes();
  }
};
</script>


<style scoped>
p{
  font-size: 12px;
  text-align: center;
}
.table-section {
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead {
  background-color: #17263d; /* Fondo azul oscuro */
  color: #ffffff; /* Texto blanco */
  font-weight: bold;
}

.table tbody td {
  padding: 10px;
  font-size: 14px;
  text-align: left;
}

.table tbody td:last-child {
  text-align: left;
}

.table tbody tr:hover {
  opacity: 0.9; /* Efecto hover */
  cursor: pointer;
}

.content {
  max-width: 70%;
  margin: 0 auto;
  background-color: #17263d;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-left: 0px;
  position:fixed;
  
}
.logo{
  width: 280px;
  margin-top: -50px;
}
.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 2px solid #2c3e50;
}
.logo-section h2 {
  margin: 0;
  color: #0db8de;
}
.subtitle {
  margin: 0;
  font-size: 14px;
}
.report-count-section {
  text-align: center;
}
.status-indicators-horizontal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.status-row, .priority-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.status-label {
  font-size: 14px;
  font-weight: bold;
}
.badge {
  padding: 2px 5px;
  border-radius: 5px;
  color: #fff;
}
.red {
  background-color: #e57373;
}
.yellow {
  background-color: #fdd835;
}
.green {
  background-color: #81c784;
}
.light-green {
  background-color: #aed581;
}
.orange {
  background-color: #ffb74d;
}
.action-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.btn-new-report {
  background-color: #0db8de;
  color: white;
}
.filters-section {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-top:20px;
  align-items: center;
}
.filter-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);


}
.filter-label {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
  background-color: transparent;
}
.filter-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #0db8de;
  font-size: 16px;
}
.filter-input{
  cursor:pointer;
}
.filter-input select,
.filter-input input {
  width: 50%;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
}
.filter-input::placeholder {
  color: #ccc;
}
.table-section {
  margin-top: 20px;
}

.table-header {
  display: flex;
  background-color: #17263d;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
}

.table-header-item {
  flex: 1;
  text-align: left;
  padding: 5px 10px;
}

.table-row {
  display: flex;
  margin-top: 10px; /* Separación entre filas */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px;
  color: #ffffff; /* Texto blanco */
}

.table-cell {
  flex: 1;
  text-align: left;
  padding: 5px 10px;
}
.priority-alta {
  background-color: #e57373;
}
.priority-media {
  background-color: #ffb74d;
}
.priority-baja {
  background-color: #81c784;
}
.btn-export {
  background-color: #0db8de;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
</style>

