<template>
    <div class="diagnostico-root">
        <div class="encabezado">Diagnostico del Estado</div>
        <div class="contenedor-eventos-problemas">
            <!-- Contenedor de Eventos -->
            <div class="eventos-container">
                <h2>Eventos de Mayor Afectación a la Gobernanza</h2>
                <div class="eventos-grid">
                    <div class="evento" v-for="evento in eventos" :key="evento.titulo">
                        <div class="numero" :style="{ backgroundColor: evento.color }">
                            {{ evento.numero }}
                        </div>
                        <div class="titulo">
                            {{ evento.titulo }}
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Contenedor de Problemas -->
            <div class="problemas-container">
                <h2>Problemas Presentes en Tlaxcala (Según su Impacto)</h2>
                <div class="problemas-grid">
                    <div class="problema" v-for="problema in problemas" :key="problema.titulo">
                        <div class="numero" :style="{ backgroundColor: problema.color }">
                            {{ problema.numero }}
                        </div>
                        <div class="titulo">
                            {{ problema.titulo }}
                        </div>
                    </div>
                </div>
            </div>
                   <!-- Contenedor del mapa -->
<!-- Contenedor del mapa -->
<div class="mapa-container">
    <div id="mapa-tlaxcala"></div>
  </div>

        </div>
        <!-- Contenedor de Top Municipios -->
        <div class="container-muncir">
            <div class="top-municipios-container">
                <div class="header">
                    <h2>Top Municipios (Incidencia Delictiva)</h2>
                </div>
                <div class="municipios-list">
                    <div class="municipio" v-for="municipio in municipios" :key="municipio.nombre">
                        <div class="icono">
                            <img src="@/assets/icons/municipio-icon.png" alt="Icono" />
                        </div>
                        <div class="nombre">{{ municipio.nombre }}</div>
                        <div class="incidente" :style="{ backgroundColor: municipio.color }">
                            {{ municipio.incidente }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="semicirculo-indicadores-container">
                <!-- Indicadores de porcentajes -->
                <div class="indicadores">
                    <div v-for="indicador in indicadores" :key="indicador.nombre" class="indicador">
                        <div class="color-box" :style="{ backgroundColor: indicador.color }"></div>
                        <div class="descripcion">
                            <h4>{{ indicador.nombre }}</h4>
                            <p>{{ indicador.valor.toLocaleString() }} Unidades</p>
                        </div>
                    </div>
                </div>
                <!-- Gráfica Semicírculo -->
                <div class="semicirculo-chart-container">
                    <div class="header">
                        <h2>Seguridad (Delitos Mayor Incidencia)</h2>
                    </div>
                    <v-chart class="semicirculo-chart" :option="graficaSemiCirculo" />
                </div>
            </div>
            <div class="embudo">
                <!-- Título del embudo -->
                <div class="funnel-title">
                  <h3>Coorrelación</h3>
                  <span>(Delitos Demandas Procesados)</span>
                </div>
              
                <!-- Gráfico del embudo -->
                <div class="funnel-chart">
                  <div
                    v-for="(dato, index) in datosFunnel"
                    :key="index"
                    class="funnel-segment"
                    :style="{ '--segment-width': `${100 - index * 20}%`, '--segment-color': dato.color }"
                  >
                    <span>{{ dato.valor }}</span>
                    {{ dato.titulo }}
                  </div>
                </div>
              
                <!-- Selector de períodos -->
                <div class="funnel-selector">
                  <button
                    v-for="(periodo, index) in periodos"
                    :key="index"
                    :class="{ active: periodo === periodoSeleccionado }"
                    @click="cambiarPeriodo(periodo)"
                  >
                    {{ periodo }}
                  </button>
                </div>
              </div>
              
              
        </div>
        <div class="row3">
        <div class="top-viralidad">
            <h2>TOP VIRALIDAD REDES SOCIALES</h2>
            <p>(Top entidades más virales Tlaxcala)</p>
        
            <div v-if="loading" class="loading">Cargando...</div>
            <div v-if="error" class="error">{{ error }}</div>
        
            <table v-else class="viralidad-table">
              <thead>
                <tr>
                  <th>Foto</th>
                  <th>Red</th>
                  <th>Seguidores</th>
                  <th>Alcance</th>
                  <th>Variabilidad</th>
                  <th>Municipio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="stakeholder in topStakeholders" :key="stakeholder.id">
                  <td><img :src="stakeholder.foto" alt="Foto" class="foto" /></td>
                  <td>{{ stakeholder.nombre }}</td>
                  <td>{{ stakeholder.seguidores.toLocaleString() }}</td>
                  <td>{{ stakeholder.alcance.toLocaleString() }}</td>
                  <td>
                    <span :class="{'up': stakeholder.variabilidad > 0, 'down': stakeholder.variabilidad < 0}">
                      {{ stakeholder.variabilidad > 0 ? '⬆' : '⬇' }}
                    </span>
                  </td>
                  <td>{{ stakeholder.municipio }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="grafica-container">
            <h2>Progresos de Investigadores por Distrito</h2>
            <div id="progresosEChart" style="width: 100%; height: 400px;"></div>
          </div>
          <div class="word-cloud-container">
            <h2>Sentimiento Digital</h2>
            <p>Con relación a las palabras de riesgo</p>
            <div id="wordCloudChart" style="width: 100%; height: 400px;"></div>
        </div>
        </div>
    </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts";
import * as echarts from "echarts";
import "echarts-wordcloud"; // Importa el wordCloud
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import apiGestor from "@/api/apiGestor";

export default {
    name: "DiagnosticoEstado",
    components: {
        "v-chart": ECharts,
    },
    data() {
        return {
            eventos: [
                { titulo: "Secuestros", numero: 59, color: "#000000" },
                { titulo: "Homicidios", numero: 22, color: "#808080" },
                { titulo: "Manifestaciones", numero: 34, color: "#33B5FF" },
                { titulo: "Cierre/Vías", numero: 15, color: "#2ECC71" },
                { titulo: "Linchamientos", numero: 5, color: "#FF5733" },
            ],
            problemas: [
                { titulo: "Seguimiento", numero: 59, color: "#00FF00" },
                { titulo: "Menor", numero: 22, color: "#FFFF00" },
                { titulo: "Moderado", numero: 34, color: "#FFA500" },
                { titulo: "Alto", numero: 15, color: "#FF4500" },
                { titulo: "Máximo", numero: 5, color: "#FF0000" },
            ],
            municipios: [
                { nombre: "Apizaco", incidente: "HOMICIDIO", color: "#FF4500" },
                { nombre: "Calpulalpan", incidente: "ROBO/VIOLENCIA", color: "#FFA500" },
                { nombre: "Huamantla", incidente: "NARCOMENUDEO", color: "#2ECC71" },
                { nombre: "Tlaxcala", incidente: "ROBO AUTOS", color: "#33B5FF" },
                { nombre: "Chiautempan", incidente: "VIOLACIÓN", color: "#FF0000" },
            ],
            indicadores: [
                { nombre: "Robo", valor: 83000, porcentaje: 35, color: "#00BFA5" },
                { nombre: "Lesiones", valor: 8000, porcentaje: 20, color: "#006064" },
                { nombre: "Robo Vehículo", valor: 70000, porcentaje: 30, color: "#26C6DA" },
                { nombre: "Narcomenudeo", valor: 15000, porcentaje: 15, color: "#B2EBF2" },
            ],

            graficaSemiCirculo: {
                title: { show: false }, // No título en la gráfica
                tooltip: { trigger: "item", formatter: "{b}: {c} ({d}%)" },
                legend: { show: false }, // Ocultar leyenda
                series: [
                    {
                        type: "pie",
                        radius: ["60%", "80%"], // Grosor del "donut"
                        center: ["50%", "50%"], // Centrado ajustado
                        startAngle: 180, // Inicia desde la parte inferior
                        label: {
                            show: true,
                            position: "outside",
                            formatter: "{d}%", // Mostrar porcentaje
                            color: "#fff", // Color de texto
                        },
                        data: [
                            { value: 35, name: "Robo", itemStyle: { color: "#00BFA5" } },
                            { value: 20, name: "Lesiones", itemStyle: { color: "#006064" } },
                            { value: 30, name: "Robo Vehículo", itemStyle: { color: "#26C6DA" } },
                            { value: 15, name: "Narcomenudeo", itemStyle: { color: "#B2EBF2" } },
                            { value: 0, name: "", itemStyle: { color: "transparent" } }, // Sección transparente
                        ],
                    },
                ],
            },
            periodoSeleccionado: "Semana",
      periodos: ["Día", "Semana", "Mes"],
      datos: {
        Día: [
          { titulo: "DELITOS", valor: 100, color: "#33b5e5" },
          { titulo: "DEMANDAS", valor: 50, color: "#2ecc71" },
          { titulo: "PROCESADOS", valor: 5, color: "#f1c40f" },
        ],
        Semana: [
          { titulo: "DELITOS", valor: 1130, color: "#33b5e5" },
          { titulo: "DEMANDAS", valor: 500, color: "#2ecc71" },
          { titulo: "PROCESADOS", valor: 20, color: "#f1c40f" },
        ],
        Mes: [
          { titulo: "DELITOS", valor: 5000, color: "#33b5e5" },
          { titulo: "DEMANDAS", valor: 2000, color: "#2ecc71" },
          { titulo: "PROCESADOS", valor: 800, color: "#f1c40f" },
        ],
        distritos: [
        { nombre: "DTO I", pendiente: 12, proceso: 8, asignado: 5, concluido: 15 },
        { nombre: "DTO II", pendiente: 10, proceso: 12, asignado: 7, concluido: 18 },
        { nombre: "DTO III", pendiente: 14, proceso: 6, asignado: 8, concluido: 20 },
        { nombre: "DTO IV", pendiente: 8, proceso: 10, asignado: 9, concluido: 14 },
        { nombre: "DTO V", pendiente: 15, proceso: 5, asignado: 10, concluido: 12 },
        { nombre: "DTO VI", pendiente: 11, proceso: 8, asignado: 12, concluido: 16 },
        { nombre: "DTO VII", pendiente: 9, proceso: 7, asignado: 6, concluido: 13 },
      ],
      },
      topStakeholders: [], // Almacena los stakeholders más virales
      loading: true, // Indica si los datos están cargando
      error: null, // Manejo de errores
    
        };
    },
    computed: {
    datosFunnel() {
      return this.datos[this.periodoSeleccionado];
    },
  },
  methods: {
    
    cambiarPeriodo(periodo) {
      this.periodoSeleccionado = periodo;
    },
    async loadTopViralidad() {
      console.log("📡 Cargando datos de viralidad...");
      const token = localStorage.getItem("gestor_token");

      if (!token) {
        console.warn("⚠️ No hay un token válido en localStorage.");
        this.$router.push("/acceso");
        return;
      }

      try {
        const response = await apiGestor.get("/stakeholders", {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log("✅ Datos obtenidos:", response.data);

        // Filtrar y mapear los datos
        this.topStakeholders = response.data.data
          .map((item) => ({
            id: item.id,
            foto: item.foto || "https://via.placeholder.com/50",
            nombre: item.nombre,
            municipio: item.municipio || "Desconocido",
            seguidores: item.seguidores || 0,
            alcance: item.alcance || 0,
            variabilidad: item.variabilidad || 0,
          }))
          .sort((a, b) => b.alcance - a.alcance) // Ordenar por alcance
          .slice(0, 4); // Tomar los 4 primeros
      } catch (error) {
        console.error("❌ Error al cargar los datos:", error);
        this.error = "No se pudo cargar la información.";
      } finally {
        this.loading = false;
      }
    },
    renderChart() {
    try {
        const chartDom = document.getElementById("progresosEChart");
        const myChart = echarts.init(chartDom);

        const option = {
            title: {
                text: "Rendimiento Coordinadores e Investigadores",
                subtext: "Reporte de las actividades realizadas",
                left: "center",
                textStyle: {
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#ffffff",
                },
                subtextStyle: {
                    fontSize: 12,
                    color: "#d1d1d1",
                },
            },
            tooltip: {
                trigger: "axis",
                axisPointer: { type: "shadow" },
            },
            legend: {
                bottom: "0%",
                textStyle: { color: "#ffffff" },
                data: ["Pendiente", "Proceso", "Asignado", "Concluido"],
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "15%",
                top: "15%",
                containLabel: true,
            },
            xAxis: {
                type: "category",
                data: this.datos.distritos.map((d) => d.nombre),
                axisLabel: {
                    rotate: 45,
                    color: "#ffffff",
                },
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: "#ffffff",
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(255, 255, 255, 0.2)",
                    },
                },
            },
            series: [
                {
                    name: "Pendiente",
                    type: "bar",
                    stack: "total",
                    data: this.datos.distritos.map((d) => d.pendiente),
                    itemStyle: {
                        color: "#2a9df4", // Azul claro para Pendiente
                    },
                },
                {
                    name: "Proceso",
                    type: "bar",
                    stack: "total",
                    data: this.datos.distritos.map((d) => d.proceso),
                    itemStyle: {
                        color: "#ffc107", // Amarillo para Proceso
                    },
                },
                {
                    name: "Asignado",
                    type: "bar",
                    stack: "total",
                    data: this.datos.distritos.map((d) => d.asignado),
                    itemStyle: {
                        color: "#6c757d", // Gris para Asignado
                    },
                },
                {
                    name: "Concluido",
                    type: "bar",
                    stack: "total",
                    data: this.datos.distritos.map((d) => d.concluido),
                    itemStyle: {
                        color: "#28a745", // Verde para Concluido
                    },
                },
            ],
        };

        myChart.setOption(option);
    } catch (error) {
        console.error("Error al renderizar la gráfica:", error);
    }
},
renderWordCloud() {
    try {
        const chartDom = document.getElementById("wordCloudChart");
        const myChart = echarts.init(chartDom);

        const option = {
            title: {
                left: "center",
                textStyle: {
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#ffffff",
                },
                subtextStyle: {
                    fontSize: 12,
                    color: "#d1d1d1",
                },
            },
            tooltip: {
                show: true,
                formatter: (params) => `${params.name}: ${params.value}`,
            },
            series: [
                {
                    type: "wordCloud",
                    shape: "circle", // Forma de la nube (circle, diamond, etc.)
                    gridSize: 2,
                    sizeRange: [12, 50], // Tamaño de las palabras
                    rotationRange: [-45, 45], // Rotación permitida
                    textStyle: {
                        normal: {
                            color: function () {
                                return `rgb(${[
                                    Math.round(Math.random() * 255),
                                    Math.round(Math.random() * 255),
                                    Math.round(Math.random() * 255),
                                ].join(",")})`;
                            },
                        },
                    },
                    data: [
                        { name: "Justicia", value: 500 },
                        { name: "Prevención", value: 400 },
                        { name: "Linchamiento", value: 350 },
                        { name: "Homicidio", value: 300 },
                        { name: "Violencia", value: 250 },
                        { name: "Secuestro", value: 200 },
                        { name: "Delincuencia", value: 150 },
                        { name: "Robo", value: 120 },
                        { name: "Asalto", value: 100 },
                        { name: "Vandalismo", value: 80 },
                        { name: "Impunidad", value: 60 },
                        { name: "Crisis", value: 50 },
                        { name: "Orden", value: 30 },
                        { name: "Pandillas", value: 20 },
                    ],
                },
            ],
        };

        myChart.setOption(option);
    } catch (error) {
        console.error("Error al renderizar la nube de palabras:", error);
    }
}

  },
  mounted() {
    // Configuración del mapa Leaflet
    this.mapa = L.map("mapa-tlaxcala").setView([19.316, -98.241], 10);

// Añadir capa satelital de Google Maps
L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
  attribution: "© OpenStreetMap contributors",
}).addTo(this.mapa);

// Cargar archivo GeoJSON de Tlaxcala
fetch("/data/tlaxcala.geojson") // 📌 Ruta dentro de `public`
  .then(response => response.json())
  .then(data => {
    this.geojsonLayer = L.geoJSON(data, {
      style: {
        color: "#FF4500",
        weight: 2,
        opacity: 1,
        fillColor: "#f03",
        fillOpacity: 0.4,
      },
    }).addTo(this.mapa);

    this.mapa.fitBounds(this.geojsonLayer.getBounds()); // Ajustar al área del GeoJSON
  })
  .catch(error => console.error("Error al cargar GeoJSON:", error));


  this.loadTopViralidad();
  this.renderChart();
  this.renderWordCloud();
},
};

</script>

<style scoped>
.diagnostico-root {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 80%;
    margin-left: 150px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -25px;
}

.encabezado {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
}

/*CONTENEDORES DE EVENTOS Y PROBLEMAS*/
.contenedor-eventos-problemas {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 55%;
}

.eventos-container {
    background-color: #1e2a38;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-left: -180px;
    width: 440px;
    height: 150px;
    position: relative;


}

.problemas-container {
    background-color: #1e2a38;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 440px;
    height: 150px;
    position: relative;
    margin-right: 950px;
}


.eventos-container::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 10%;
    right: 10%;
    height: 3px;
    background: linear-gradient(to right, #000, #808080, #33B5FF, #2ECC71, #FF5733);
    z-index: 0;
}

.problemas-container::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 10%;
    right: 10%;
    height: 3px;
    background: linear-gradient(to right, #00FF00, #FFFF00, #FFA500, #FF4500, #FF0000);
    z-index: 0;
}

.problemas-container h2,
.eventos-container h2 {
    color: white;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}

.problemas-grid,
.eventos-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
    width: 100%;
    position: relative;
    /* Asegura que los eventos se alineen bien */
    z-index: 1;
}

.problema,
.evento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    padding: 10px 0;
    position: relative;
    z-index: 2;
}

.numero {
    width: 45px;
    height: 33px;
    border-radius: 50%;
    background-color: var(--numero-color, #ffffff);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease;
}
.numero:hover{
  transform: scale(1.2);
  background-color: #ffffff;
  color: var(--numero-color, #ffffff);
}

.titulo {
    margin-top: 5px;
    color: white;
    font-size: 10px;
    text-align: center;
}

.container-muncir {
    display: flex; /* Alineación horizontal de elementos hijos */
    gap: 15px; /* Espaciado entre los gráficos */
    justify-content: center; /* Asegura que estén centrados horizontalmente */
    align-items: flex-start; 
    margin-top: -260px;
}

/*TOP MUNICIPIOS*/
.top-municipios-container {
    background-color: #1e2a38;
    padding: 20px;
    border-radius: 8px;
    margin-top: 5px;
    margin-left: -180px;
    width: 250px;
    height: 250px;
}

.top-municipios-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h2 {
    color: white;
    font-size: 12px;
    margin: 0;
    font-weight: bold;
    text-align: center;
    justify-content: center;

}


.municipios-list {
    margin-top: 10px;
}

.municipio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a3b4d;
    border-radius: 8px;
    padding: 3px;
    margin-bottom: 7px;
}

.icono img {
    width: 25px;
    height: 25px;
}

.nombre {
    flex: 1;
    color: white;
    font-size: 10px;
    margin-left: 10px;
}

.incidente {
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    font-size: 6px;
    font-weight: bold;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.incidente:hover {
    transform: scale(2);
    /* 🔹 Aumenta el tamaño */
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.3);
    /* 🔹 Agrega sombra */
}

/* Estilos del Contenedor de Semicírculo */
.semicirculo-indicadores-container {
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: rgb(30, 42, 56);
    padding: 20px;
    border-radius: 8px;
    margin-right: 950px;
    height: 250px;
}

.indicadores {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.indicador {
    display: flex;
    align-items: center;
    gap: 10px;
}

.color-box {
    width: 10px;
    height: 10px;
    border-radius: 4px;
}

.descripcion {
    color: white;
}

.descripcion h4 {
    margin: 0;
    font-size: 10px;
}

.descripcion p {
    margin: 0;
    font-size: 9px;
    color: #b0bec5;
}

.semicirculo-chart-container {
    flex: 2;
    text-align: center;
    margin-bottom: 20px;
    height: auto;
    width: 200px;

}

.semicirculo-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.semicirculo-chart-container h2 {
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}

.semicirculo-chart {
    width: 170px;
    height: 170px;
}
/* Contenedor general del embudo */
.embudo {
  flex: 1; /* Toma una proporción igual del espacio disponible */
  flex-direction: column;
  background-color: #1e2a38;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; 
  margin-left: -950px;
  height: 250px;
  width: 270px;

  }

  
/* Título del embudo */
.funnel-title h3 {
    color: white;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
  .funnel-title span {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 300;
  }
  
  /* Gráfico del embudo */
  .funnel-chart {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .funnel-segment {
    width: var(--segment-width);
    background-color: var(--segment-color);
    padding: 10px 0;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 15% 100%); /* Forma de trapecio */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .funnel-segment:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Selector de períodos */
  .funnel-selector {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .funnel-selector button {
    background-color: #1e4059;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .funnel-selector button:hover {
    background-color: #33b5e5;
  }
  
  .funnel-selector button.active {
    background-color: #2ecc71;
    font-weight: bold;
  }
  

/* Contenedor del mapa */
.mapa-container {
    flex:1;
    background-color: #1e2a38; /* Fondo oscuro */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -960px;
    height: 400px;
    z-index: 99;
    width: 300px;
  }
  
  /* Estilo del iframe del mapa */

  
  #mapa-tlaxcala {
    width: 100%;
    height: 390px; /* Ajusta la altura según el diseño */
    border-radius: 8px;
  }
  
  /* Estilos para el dashboard */
  .row3{
    flex:1;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 55%;

  }
.top-viralidad {
    background-color: #1e2a38;
    color: white;
    padding: 1rem;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    margin-left: -180px;
    width: 500px;
  }
  
  .top-viralidad h2 {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .viralidad-table {
    width: 350px;
    border-collapse: collapse;
  }
  
  .viralidad-table th,
  .viralidad-table td {
    padding: 0.5rem;
    text-align: center;
    border-bottom: 1px solid #2a4a6e;
  }
  
  .foto {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  
  .up {
    color: green;
  }
  
  .down {
    color: red;
  }
  
  .loading,
  .error {
    text-align: center;
    font-size: 1rem;
    margin: 1rem 0;
  }
  .grafica-container {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding: 1rem;
    background-color: #1e2a38;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: -5px;
  }
  .grafica-container h2{
    font-size: 14px;
  }
  .word-cloud-container {
    margin: 15px 0;
    text-align: center;
    background-color: #1e2a38;
    border-radius: 15px;
    margin-right: 5px;
    margin-left: -70px;
    margin-top: -5px;
    width: 350px;
}

.word-cloud-container h2 {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
   margin-top: 25px;
}

.word-cloud-container p {
    font-size: 14px;
    color: #d1d1d1;
    margin-bottom: 10px;
}


</style>